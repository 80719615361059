import { useState, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { useCallApi } from '../../config/hooks/useCallApi';

import './pdfViewer.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/'
};

const PdfViewer = ({ file }: { file: string | File | undefined }) => {
    const { LoaderElement } = useCallApi();

    const [numPages, setNumPages] = useState<number>();
    const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
    const [containerWidth, setContainerWidth] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const resizeObserverOptions = {};
    const maxWidth = 800;

    const onResize = useCallback<ResizeObserverCallback>(entries => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    function onDocumentLoadSuccess({ numPages: nextNumPages }): void {
        setNumPages(nextNumPages);

        if (isLoading) {
            setIsLoading(false);
        }
    }

    return (
        <div className='pdfviewer-container'>
            {isLoading && <LoaderElement />}
            <div className='pdfviewer-container__content'>
                <div className='' ref={setContainerRef}>
                    <Document
                        file={file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={options}
                        loading={null}
                    >
                        {Array.from(new Array(numPages), (_el, index) => (
                            <div key={`page__${index + 1}`}>
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={
                                        containerWidth
                                            ? Math.min(containerWidth, maxWidth)
                                            : maxWidth
                                    }
                                    loading={null}
                                />
                            </div>
                        ))}
                    </Document>
                </div>
            </div>
        </div>
    );
};

export default PdfViewer;
