export interface QRInterface {
    id: string;
    name: string;
    description: string;
    destination: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface QRFormCreateInterface {
    name: string;
    description: string;
    type: string;
    destination: string;
    'external-url'?: string;
    'internal-url'?: string;
    pdf?: File[];
    photo?: File[];
    qr_code_url?: string;
    qrDestinationLink?: string;
}

export const QRFormValues: QRFormCreateInterface = {
    name: '',
    description: '',
    type: '',
    destination: '',
    pdf: [],
    photo: [],
    'external-url': '',
    'internal-url': ''
};
