import '../settings.scss';
import { useState, /* MouseEvent, */ useEffect } from 'react';
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import { Calendar, type Event, momentLocalizer } from 'react-big-calendar';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
//  <--INTERFACE--> //
import { lang } from '../../langs';
import {
    ApiCalendarByProperties,
    ApiCalendarDetailDeleteId
} from '../../../config/service';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import { useToast } from '../../../config/hooks/useToast';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import CalendarHeader from './components/calendar-header';
import EventInfo from './components/eventInfo';
/* import AddEventModal from './components/addEventModal'; */
import EventInfoModal from './components/eventInfoModal';
import { AddTodoModal } from './components/addTodoModal';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es', {
    week: {
        dow: 1,
        doy: 4
    }
});
const localizer = momentLocalizer(moment);
/* import AddDatePickerEventModal from "./AddDatePickerEventModal" */

export interface ITodo {
    _id: string;
    title: string;
    color: string;
    disabled: boolean;
}

interface RecurrentDetailInterface {
    days: number[];
    weeks: number;
}

export interface IEventInfo extends Event {
    _id: string;
    description: string;
    hour?: string;
    note?: string;
    recurrent?: boolean;
    recurrentDetail?: RecurrentDetailInterface;
    todoId?: string;
    title?: string;
    property?: string;
    propertyName?: string;
    propertyAddress?: string;
    propertyUnit?: string;
    propertyUnitName?: string;
    gestor?: string;
    gestorName?: string;
    gestorContactName?: string;
    gestorContactEmail?: string;
    dataFont: string;
    executed?: number;
    truck?: string;
    patent?: string;
    gestorUser?: string;
    gestorUserName?: string;
    show?: boolean;
}

export interface EventFormData {
    description: string;
    todoId?: string;
}

export interface DatePickerEventFormData {
    description: string;
    todoId?: string;
    allDay: boolean;
    start?: Date;
    end?: Date;
}

export const generateId = () =>
    (Math.floor(Math.random() * 10000) + 1).toString();

/* const initialEventFormState: EventFormData = {
    description: '',
    todoId: undefined
}; */

/* const initialDatePickerEventFormData: DatePickerEventFormData = {
  description: "",
  todoId: undefined,
  allDay: false,
  start: undefined,
  end: undefined,
} */

const CalendarPage = () => {
    const { selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { preferences, userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;

    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageInvites: { title }
        }
    } = lang;
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    /* const [openSlot, setOpenSlot] = useState(false); */
    /* const [openDatepickerModal, setOpenDatepickerModal] = useState(false) */
    const [openTodoModal, setOpenTodoModal] = useState<boolean>(false);
    const [currentEvent, setCurrentEvent] = useState<Event | IEventInfo | null>(
        null
    );
    const [eventInfoModal, setEventInfoModal] = useState(false);
    const [events, setEvents] = useState<IEventInfo[]>([]);
    const [todos, setTodos] = useState<ITodo[]>([
        {
            _id: generateId(),
            title: 'Retiros por gestor',
            color: '#03DAC5',
            disabled: false
        },
        {
            _id: generateId(),
            title: 'Bolsas',
            color: '#65C05A',
            disabled: true
        },
        {
            _id: generateId(),
            title: 'Recolección',
            color: '#2994E6',
            disabled: true
        },
        {
            _id: generateId(),
            title: 'SINADER',
            color: '#FDDF4A',
            disabled: true
        },
        {
            _id: generateId(),
            title: 'Certificados',
            color: '#EC8121',
            disabled: true
        }
    ]);
    /* const [eventFormData, setEventFormData] = useState<EventFormData>(
        initialEventFormState
    ); */

    /* const [datePickerEventFormData, setDatePickerEventFormData] =
        useState<DatePickerEventFormData>(initialDatePickerEventFormData) */

    const [date, setDate] = useState(moment().format('YYYY-MM'));
    const [view, setView] = useState<'month' | 'week' | 'day'>('month');
    const [color, setColor] = useState<string | undefined>('');

    const handleSelectSlot = (event: Event) => {
        /* setOpenSlot(true); */
        setCurrentEvent(event);
    };

    const handleSelectEvent = (event: IEventInfo) => {
        setCurrentEvent(event);
        setEventInfoModal(true);
        const todo = todos.find(todo => todo._id === event.todoId);
        if (todo) {
            setColor(todo.color);
        }
    };

    /* const handleClose = () => {
        setEventFormData(initialEventFormState);
        setOpenSlot(false);
    }; */

    /* const handleDatePickerClose = () => {
        setDatePickerEventFormData(initialDatePickerEventFormData)
        setOpenDatepickerModal(false)
    } */

    /* const onAddEvent = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const data: IEventInfo = {
            ...eventFormData,
            _id: generateId(),
            start: currentEvent?.start,
            end: currentEvent?.end
        };

        const newEvents = [...events, data];

        setEvents(newEvents);
        handleClose();
    }; */

    /* const onAddEventFromDatePicker = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        const addHours = (date: Date | undefined, hours: number) => {
        return date ? date.setHours(date.getHours() + hours) : undefined
        }

        const setMinToZero = (date: any) => {
        date.setSeconds(0)

        return date
        }

        const data: IEventInfo = {
        ...datePickerEventFormData,
        _id: generateId(),
        start: setMinToZero(datePickerEventFormData.start),
        end: datePickerEventFormData.allDay
            ? addHours(datePickerEventFormData.start, 12)
            : setMinToZero(datePickerEventFormData.end),
        }

        const newEvents = [...events, data]

        setEvents(newEvents)
        setDatePickerEventFormData(initialDatePickerEventFormData)
    } */

    const onDeleteEvent = async (all: boolean, event: string | undefined) => {
        if (event) {
            const { status, message } = await GetData(
                ApiCalendarDetailDeleteId(event),
                HTTP_METHODS.POST,
                { all }
            );
            if (status) {
                setEventInfoModal(false);
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Success;
                setConfigToast(ConfigToast);
                CallData();
            } else {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    const handleNavigate = newDate => {
        setDate(moment(newDate).format('YYYY-MM'));
    };

    const CallData = async () => {
        let gestor: string | null = null;
        const rolesList = userData.roles.map(role => role.id);
        for (const role of userData.roles) {
            if (role.gestorData) {
                gestor = role.gestorData.id;
                break;
            }
        }

        const { data: calendars } = await GetData(
            ApiCalendarByProperties,
            HTTP_METHODS.POST,
            { userId: userData.id, rolesList, date, gestor }
        );

        const events = calendars.flatMap(calendar =>
            calendar.calendarDetails.map(calendarDetail => {
                const date = new Date(calendarDetail.date);
                const start = new Date(date);
                start.setHours(0, 0, 0, 0); // Inicio del día
                const end = new Date(date);
                end.setHours(23, 59, 59, 999); // Fin del día

                return {
                    _id: calendarDetail.calendarDetail,
                    title: calendar.title,
                    description: calendar.description || '',
                    recurrent: calendar.recurrent === 1,
                    recurrentDetail: calendar.recurrentDetail
                        ? JSON.parse(calendar.recurrentDetail)
                        : calendar.recurrentDetail,
                    hour: calendarDetail.hour,
                    note: calendarDetail.note,
                    property: calendar.property,
                    propertyName: calendar.propertyName,
                    propertyAddress: calendar.propertyAddress,
                    propertyUnit: calendar.propertyUnit,
                    propertyUnitName: calendar.propertyUnitName,
                    gestor: calendar.gestor,
                    gestorName: calendar.gestorName,
                    gestorContactName: calendar.gestorContactName,
                    gestorContactEmail: calendar.gestorContactEmail,
                    dataFont: calendar.dataFont,
                    start,
                    end,
                    executed: calendarDetail.executed,
                    truck: calendarDetail.truck,
                    patent: calendarDetail.patent,
                    gestorUser: calendarDetail.gestorUser,
                    gestorUserName: calendarDetail.gestorUserName,
                    todoId: todos.find(todo => todo.title === calendar.category)
                        ?._id,
                    allDay: true,
                    show: true
                };
            })
        );

        // Ordenamos eventos por día y luego por la hora (AM primero, luego PM)
        const sortedEvents = events.sort((a, b) => {
            const dateA = a.start.toISOString().slice(0, 10); // Obtiene solo la fecha en formato yyyy-mm-dd
            const dateB = b.start.toISOString().slice(0, 10);

            // Si las fechas son diferentes, ordena por fecha
            if (dateA !== dateB) {
                return new Date(dateA).getTime() - new Date(dateB).getTime();
            }

            // Si las fechas son iguales, ordena por `hour`, colocando 'am' antes que 'pm'
            if (a.hour === 'am' && b.hour === 'pm') {
                return -1; // a va antes que b
            } else if (a.hour === 'pm' && b.hour === 'am') {
                return 1; // b va antes que a
            }
            return 0; // Si ambos tienen la misma `hour`, mantienen su orden
        });
        setEvents(sortedEvents);
    };

    useEffect(() => {
        CallData();
    }, [date]);

    useEffect(() => {
        if (events.length > 0) {
            const newEvents = events.map(event => {
                const show =
                    selected.length === 0 ||
                    selected.some(sel => sel.id === event.property);
                return { ...event, show };
            });
            setEvents(newEvents);
        }
    }, [selected]);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/CalendarIcon.svg'
                kind='settings'
                option='Calendar'
            ></SubNavBar>
            <div className='settings title'>
                {/* <div className='settings__calendarBtns subtitle'>
                <button className='settings__calendarBtns__item' onClick={() => setOpenDatepickerModal(true)} >
                    Crear evento
                </button>
                <button className='settings__calendarBtns__item' onClick={() => setOpenTodoModal(true)} >
                    Crear categoría
                </button>
            </div> */}
                {/* <AddEventModal
                    open={openSlot}
                    handleClose={handleClose}
                    eventFormData={eventFormData}
                    setEventFormData={setEventFormData}
                    onAddEvent={onAddEvent}
                    todos={todos}
                /> */}
                {/* <AddDatePickerEventModal
            open={openDatepickerModal}
            handleClose={handleDatePickerClose}
            datePickerEventFormData={datePickerEventFormData}
            setDatePickerEventFormData={setDatePickerEventFormData}
            onAddEvent={onAddEventFromDatePicker}
            todos={todos}
            /> */}
                <EventInfoModal
                    open={eventInfoModal}
                    handleClose={() => setEventInfoModal(false)}
                    onDeleteEvent={onDeleteEvent}
                    currentEvent={currentEvent as IEventInfo}
                    color={color}
                    onRefresh={CallData}
                />
                <AddTodoModal
                    open={openTodoModal}
                    handleClose={() => setOpenTodoModal(false)}
                    todos={todos}
                    setTodos={setTodos}
                />
                <div
                    className={`settings__calendar subtitle ${
                        view === 'week' || view === 'day' ? 'week-view' : ''
                    }`}
                >
                    <Calendar
                        localizer={localizer}
                        events={events.filter(event => event.show)}
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                        selectable
                        startAccessor='start'
                        components={{
                            event: props => (
                                <EventInfo
                                    {...props}
                                    view={view} // Pasamos `view` como prop
                                />
                            ),
                            toolbar: props => (
                                <CalendarHeader
                                    {...props}
                                    todos={todos}
                                    view={view}
                                    setView={setView}
                                />
                            )
                        }}
                        endAccessor='end'
                        view={view}
                        views={['month', 'week', 'day']}
                        formats={{
                            weekdayFormat: date => moment(date).format('dddd'),
                            timeGutterFormat: ' A'
                        }}
                        messages={{
                            next: '>',
                            previous: '<',
                            today: 'Hoy',
                            month: 'Mes',
                            week: 'Semana',
                            day: 'Día',
                            showMore: total => `+${total} eventos más`
                        }}
                        eventPropGetter={event => {
                            const hasTodo = todos.find(
                                todo => todo._id === event.todoId
                            );
                            return {
                                style: {
                                    borderLeft: hasTodo
                                        ? `5px solid ${hasTodo.color}`
                                        : `5px solid #b64fc8`
                                }
                            };
                        }}
                        onNavigate={handleNavigate}
                        onView={(newView: any) => setView(newView)} // Envuelve `setView` en una función anónima
                        onShowMore={() => {
                            setView('day'); // Cambia la vista a "day" al hacer clic en "+ more"
                        }}
                    />
                </div>
            </div>
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default CalendarPage;
