import '../create-calendar.scss';
import { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, ConfigProvider, DatePicker, Input, Select } from 'antd';
import { useCallApi } from '../../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../../config/hooks/useCallApi/constants';
import { useToast } from '../../../../../../config/hooks/useToast';
import { UserStoreInterface } from '../../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../../store/hooks';
import {
    ApiPropertyByUserRole,
    ApiGestor,
    ApiMaterial,
    ApiCalendar,
    ApiCalendarEditId,
    ApiFontId,
    ApiTruckByGestorList,
    ApiGestorUserByGestorId,
    ApiCalendarDetailId
} from '../../../../../../config/service';
import { NormalizeName } from '../../../../../../config/utils/Utilities';
import { IEventInfo } from '../../../calendar';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
    onClose: () => void;
    onRefresh?: () => void;
    currentEvent?: IEventInfo | null;
}

interface FormInterface {
    title: string;
    description: string;
    category: string;
    property: string | null;
    gestor: string | null;
    materials: string[];
    startDate: Dayjs | null;
    hour: string | null;
    recurrent: boolean;
    recurrentDetail: string | null;
    clientUser: string | null;
    truck?: string | null;
    gestorUser?: string | null;
    note?: string;
}

const CreateEvent = ({ onClose, onRefresh, currentEvent }: Props) => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { userData, defaultProfile }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const selectedRole = roles[defaultProfile];
    const [formReady, setFormReady] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [selectedDays, setSelectedDays] = useState<number[]>([]);
    const [properties, setProperties] = useState<any[]>([]);
    const [gestors, setGestors] = useState<any[]>([]);
    const [materials, setMaterials] = useState<any[]>([]);
    const [trucks, setTrucks] = useState<any[]>([]);
    const [gestorUsers, setGestorUsers] = useState<any[]>([]);
    const [weeks, setWeeks] = useState<number | null>(null);
    const [formData, setFormData] = useState<FormInterface>({
        title: '',
        description: '',
        category: 'Retiros por gestor',
        property: null,
        gestor: null,
        materials: [],
        startDate: null,
        hour: null,
        recurrent: false,
        recurrentDetail: null,
        clientUser: null,
        truck: null,
        gestorUser: null,
        note: ''
    });
    const [initialData, setInitialData] = useState<any>();

    const onChange = (value, field) => {
        if (value[0] === 'selectAll') {
            setFormData(prevData => ({
                ...prevData,
                [field]: materials.map(material => material.value)
            }));
        } else {
            setFormData(prevData => ({ ...prevData, [field]: value }));
        }
        setShowAlert(false);
    };

    const handleWeeksChange = (value: number) => {
        setWeeks(value);
    };

    // Función para manejar el clic en un día de la semana
    const handleDayClick = (day: number) => {
        setSelectedDays(prevSelectedDays => {
            if (prevSelectedDays.includes(day)) {
                return prevSelectedDays.filter(
                    selectedDay => selectedDay !== day
                );
            } else {
                return [...prevSelectedDays, day];
            }
        });
    };

    // Array de días con identificadores únicos para martes y miércoles
    const daysOfWeek = [
        { id: 1, label: 'L' },
        { id: 2, label: 'M' }, // Martes
        { id: 3, label: 'M' }, // Miércoles
        { id: 4, label: 'J' },
        { id: 5, label: 'V' },
        { id: 6, label: 'S' },
        { id: 7, label: 'D' }
    ];

    const CallData = async () => {
        const { data: clients } = await GetData(
            ApiPropertyByUserRole(userData.id),
            HTTP_METHODS.GET,
            {},
            [selectedRole.id]
        );
        const properties = clients?.flatMap(client => client.properties) || [];
        const uniqueProperties: any = Array.from(
            new Map(
                properties.map(property => [property.id, property])
            ).values()
        );
        const formattedProperties = uniqueProperties
            .map(property => ({
                value: property.id,
                label: property.name
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        setProperties(formattedProperties);

        const { data: gestors } = await GetData(ApiGestor, HTTP_METHODS.GET);
        const formattedGestors = gestors
            .map(gestor => ({
                value: gestor.id,
                label: gestor.name
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        setGestors(formattedGestors);

        const { data: materials } = await GetData(
            ApiMaterial,
            HTTP_METHODS.GET
        );
        const formattedMaterials = materials
            .map(material => ({
                value: material.id,
                label: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            className='calendarRequest__main__form__material-label-img'
                            src={`/svg-icons/material-icons/${NormalizeName(
                                material.name
                            )}.svg`}
                            onError={e => {
                                const target = e.target as HTMLImageElement;
                                target.src =
                                    '/svg-icons/material-icons/general.svg';
                            }}
                        />
                        {material.name} - {material.codeLer}
                    </div>
                )
            }))
            .sort((a, b) =>
                a.label.props.children[1].localeCompare(
                    b.label.props.children[1]
                )
            );
        setMaterials(formattedMaterials);

        if (currentEvent?.gestor) {
            const { data: trucksData } = await GetData(
                ApiTruckByGestorList,
                HTTP_METHODS.POST,
                { list: [currentEvent.gestor] } // Pasar el gestor como clave-valor
            );
            const trucks = trucksData
                .map(truck => ({
                    value: truck.id,
                    label: truck.patent
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setTrucks(trucks);

            const { data: gestorUsersData } = await GetData(
                ApiGestorUserByGestorId(currentEvent.gestor),
                HTTP_METHODS.GET
            );
            const gestorUsers = gestorUsersData
                .map(gestorUser => ({
                    value: gestorUser.id,
                    label: `${gestorUser.user.name} ${gestorUser.user.lastName}`
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            setGestorUsers(gestorUsers);
        }
        setFormReady(true);
    };

    const GetEntity = async () => {
        if (currentEvent) {
            const { data: dataFont } = await GetData(
                ApiFontId(currentEvent.dataFont),
                HTTP_METHODS.GET,
                {},
                [selectedRole.id]
            );
            const materials = dataFont.dataRequirements.map(
                requirement => requirement.material.id
            );

            // Actualizar formData y otros estados
            setFormData(prevFormData => ({
                ...prevFormData,
                title: currentEvent.title ?? '',
                description: currentEvent.description ?? '',
                property: currentEvent.property ?? null,
                gestor: currentEvent.gestor ?? null,
                materials,
                hour: currentEvent.hour ?? null,
                recurrent: currentEvent.recurrent ?? false,
                startDate: currentEvent.start
                    ? dayjs(currentEvent.start)
                    : null,
                truck: currentEvent.truck ?? null,
                gestorUser: currentEvent.gestorUser ?? null,
                note: currentEvent.note ?? ''
            }));
            setInitialData({
                title: currentEvent.title ?? '',
                description: currentEvent.description ?? '',
                property: currentEvent.property ?? null,
                gestor: currentEvent.gestor ?? null,
                materials,
                hour: currentEvent.hour ?? null,
                recurrent: currentEvent.recurrent ?? false,
                startDate: currentEvent.start
                    ? dayjs(currentEvent.start)
                    : null,
                truck: currentEvent.truck ?? null,
                gestorUser: currentEvent.gestorUser ?? null,
                note: currentEvent.note ?? ''
            });

            // Actualizar los estados para días y semanas
            setSelectedDays(currentEvent?.recurrentDetail?.days ?? []);
            setWeeks(currentEvent?.recurrentDetail?.weeks ?? 1); // Si no hay semanas, usar 1 como predeterminado
        }
    };

    const validateForm = () => {
        const {
            title,
            description,
            property,
            gestor,
            materials,
            startDate,
            hour
        } = formData;
        if (
            !title ||
            !description ||
            !property ||
            !gestor ||
            !materials.length ||
            !startDate ||
            !hour
        ) {
            setShowAlert(true);
            return false;
        } else {
            setShowAlert(false);
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        // Preparar la data para enviar
        const eventData = {
            ...formData,
            recurrentDetail: formData.recurrent
                ? JSON.stringify({
                      days: selectedDays,
                      weeks: weeks ?? 1 // Si no se seleccionan semanas, asumimos 1 como predeterminado
                  })
                : null
        };

        const areArraysEqual = (arr1, arr2) =>
            arr1.length === arr2.length &&
            arr1.every((val, index) => val === arr2[index]);

        const onlyRelevantFieldsChanged = () => {
            // Verifica todos los campos irrelevantes
            const allUnchanged =
                initialData.title === eventData.title &&
                initialData.description === eventData.description &&
                initialData.property === eventData.property &&
                initialData.gestor === eventData.gestor &&
                areArraysEqual(initialData.materials, eventData.materials) && // Comparar arrays
                initialData.hour === eventData.hour &&
                initialData.recurrent === eventData.recurrent &&
                initialData.startDate.isSame(eventData.startDate, 'day'); // Comparar fechas

            // Verifica si algún campo relevante ha cambiado
            const anyRelevantChanged =
                initialData.truck !== eventData.truck ||
                initialData.gestorUser !== eventData.gestorUser ||
                initialData.note !== eventData.note;

            return allUnchanged && anyRelevantChanged;
        };

        try {
            /* await GetData(ApiCalendarCreate, HTTP_METHODS.POST, eventData); */
            setShowAlert(false);
            if (currentEvent) {
                if (onlyRelevantFieldsChanged()) {
                    const { status, message } = await GetData(
                        ApiCalendarDetailId(currentEvent._id),
                        HTTP_METHODS.PATCH,
                        eventData
                    );
                    if (status) {
                        ConfigToast.text = message;
                        ConfigToast.backColor = Colors.Success;
                        setConfigToast(ConfigToast);
                        onClose();
                        if (onRefresh) {
                            onRefresh();
                        }
                    } else {
                        ConfigToast.text = message;
                        ConfigToast.backColor = Colors.Error;
                        ConfigToast.title = 'Error';
                        setConfigToast(ConfigToast);
                    }
                } else {
                    const { status, message } = await GetData(
                        ApiCalendarEditId(currentEvent._id),
                        HTTP_METHODS.POST,
                        eventData
                    );
                    if (status) {
                        ConfigToast.text = message;
                        ConfigToast.backColor = Colors.Success;
                        setConfigToast(ConfigToast);
                        onClose();
                        if (onRefresh) {
                            onRefresh();
                        }
                    } else {
                        ConfigToast.text = message;
                        ConfigToast.backColor = Colors.Error;
                        ConfigToast.title = 'Error';
                        setConfigToast(ConfigToast);
                    }
                }
            } else {
                const { status, message } = await GetData(
                    ApiCalendar,
                    HTTP_METHODS.POST,
                    eventData
                );
                if (status) {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Success;
                    setConfigToast(ConfigToast);
                    onClose();
                    if (onRefresh) {
                        onRefresh();
                    }
                } else {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Error;
                    ConfigToast.title = 'Error';
                    setConfigToast(ConfigToast);
                }
            }
            // Aquí puedes resetear el formulario si es necesario
        } catch (error) {
            console.error('Error al crear el evento', error);
        }
    };

    const resetForm = () => {
        setFormData({
            title: '',
            description: '',
            category: 'Retiros por gestor',
            property: null,
            gestor: null,
            materials: [],
            startDate: null,
            hour: null,
            recurrent: false,
            recurrentDetail: null,
            clientUser: null,
            truck: null,
            gestorUser: null,
            note: ''
        });
        // Resetear otras variables de estado relacionadas si es necesario
        setSelectedDays([]); // Si deseas limpiar los días seleccionados
        setWeeks(1); // Si deseas restablecer la semana a un valor predeterminado
        setShowAlert(false); // Si deseas esconder el mensaje de alerta
    };

    useEffect(() => {
        CallData();
    }, [currentEvent]);

    useEffect(() => {
        if (formReady) {
            GetEntity();
        }
    }, [currentEvent, formReady]);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <div className='calendarRequest__main__menu subsubtitle'>
                <CloseOutlined
                    className='calendarRequest__main__menu__close'
                    onClick={onClose}
                />
                <hr />
            </div>
            <div className='calendarRequest__main__header title'>
                <div className='calendarRequest__main__header__title'>
                    <div className='calendarRequest__main__header__title__circle' />
                    <div className='calendarRequest__main__header__title__text'>
                        {currentEvent ? 'Editar' : 'Crear'} evento de retiro por
                        gestor
                    </div>
                </div>
            </div>
            <div className='calendarRequest__main__text subtitle'>
                <ConfigProvider
                    theme={{
                        components: {
                            Checkbox: {
                                colorPrimary: `#C4C3C5`,
                                colorBorder: `#C4C3C5`,
                                colorPrimaryHover: `#C4C3C5`
                            },
                            Select: {
                                colorPrimary: '#2994E6', // Cambiar color primario del Select
                                colorBgContainerDisabled: '#f3f2f5',
                                controlItemBgHover: '#f3f2f5',
                                colorBgElevated: '#f3f2f5',
                                activeBorderColor: '#2994E6',
                                hoverBorderColor: '#2994E6',
                                optionSelectedColor: '#4d4d4d',
                                optionFontSize: 12,
                                fontSize: 12
                            },
                            Input: {
                                colorPrimary: '#2994E6', // Cambiar color primario del Select
                                colorBgContainerDisabled: '#f3f2f5',
                                controlItemBgHover: '#f3f2f5',
                                colorBgElevated: '#f3f2f5',
                                activeBorderColor: '#2994E6',
                                hoverBorderColor: '#2994E6',
                                fontSize: 12
                            },
                            DatePicker: {
                                colorPrimary: '#2994E6', // Cambiar color primario del Select
                                colorBgContainerDisabled: '#f3f2f5',
                                controlItemBgHover: '#f3f2f5',
                                colorBgElevated: '#f3f2f5',
                                activeBorderColor: '#2994E6',
                                hoverBorderColor: '#2994E6',
                                fontSize: 12
                            }
                        }
                    }}
                >
                    <form
                        className='calendarRequest__main__form'
                        onSubmit={e => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <label className='calendarRequest__main__form__label'>
                            Título:
                        </label>
                        <Input
                            placeholder='Ingresa un título'
                            count={{
                                show: true,
                                max: 20
                            }}
                            value={formData.title}
                            onChange={e => onChange(e.target.value, 'title')}
                        />
                        <label className='calendarRequest__main__form__label'>
                            Descripción:
                        </label>
                        <Input
                            placeholder='Ingresa una descripción'
                            count={{
                                show: true,
                                max: 200
                            }}
                            value={formData.description}
                            onChange={e =>
                                onChange(e.target.value, 'description')
                            }
                        />
                        <label className='calendarRequest__main__form__label'>
                            Inmueble:
                        </label>
                        <Select
                            className='calendarRequest__main__form__select'
                            showSearch
                            placeholder='Selecciona un inmueble'
                            optionFilterProp='label'
                            onChange={value => onChange(value, 'property')}
                            options={properties}
                            value={formData.property}
                        />
                        <label className='calendarRequest__main__form__label'>
                            Gestor:
                        </label>
                        <Select
                            showSearch
                            placeholder='Selecciona un gestor'
                            optionFilterProp='label'
                            onChange={value => onChange(value, 'gestor')}
                            options={gestors}
                            value={formData.gestor}
                        />
                        <label className='calendarRequest__main__form__label'>
                            Materiales:
                        </label>
                        <Select
                            mode='multiple'
                            showSearch
                            placeholder='Selecciona los materiales'
                            optionFilterProp='label'
                            onChange={value => onChange(value, 'materials')}
                            value={formData.materials}
                            options={[
                                {
                                    value: 'selectAll',
                                    label: 'SELECCIONAR TODOS'
                                },
                                ...materials
                            ]}
                            filterOption={(input, option) =>
                                option?.label
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            maxTagCount={10}
                            maxTagPlaceholder={omittedValues =>
                                `+${omittedValues.length} más`
                            }
                        />
                        <label className='calendarRequest__main__form__label'>
                            Fecha:
                        </label>
                        <div className='calendarRequest__main__form__date'>
                            <DatePicker
                                className='calendarRequest__main__form__date__date-picker'
                                placeholder='Seleccione una fecha'
                                value={formData.startDate} // Esto ya es un objeto Dayjs
                                onChange={date => onChange(date, 'startDate')} // date será un Dayjs o null
                            />
                            <Select
                                className='calendarRequest__main__form__date__hour'
                                showSearch
                                placeholder='hr'
                                optionFilterProp='label'
                                onChange={value => onChange(value, 'hour')}
                                options={[
                                    { value: 'am', label: 'am' },
                                    { value: 'pm', label: 'pm' }
                                ]}
                                value={formData.hour}
                            />
                        </div>
                        <div className='calendarRequest__main__form__recurrency'>
                            <div className='calendarRequest__main__form__recurrency__checkbox'>
                                <Checkbox
                                    checked={formData.recurrent}
                                    onChange={e =>
                                        onChange(e.target.checked, 'recurrent')
                                    }
                                />
                                <span className='calendarRequest__main__form__recurrency__checkbox__label'>
                                    Se repite
                                </span>
                            </div>
                            {formData.recurrent && (
                                <div className='calendarRequest__main__form__recurrency__details'>
                                    Cada
                                    <Select
                                        className='calendarRequest__main__form__recurrency__details__weeks'
                                        showSearch
                                        variant='borderless'
                                        placeholder='1'
                                        value={weeks} // Mostrar la semana seleccionada
                                        optionFilterProp='label'
                                        onChange={handleWeeksChange}
                                        options={[
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' }
                                        ]}
                                    />
                                    semanas, los días
                                    <div className='calendarRequest__main__form__recurrency__details__days'>
                                        {daysOfWeek.map(day => (
                                            <button
                                                key={day.id}
                                                className={`calendarRequest__main__form__recurrency__details__days__item ${
                                                    selectedDays.includes(
                                                        day.id
                                                    )
                                                        ? 'day-active'
                                                        : ''
                                                }`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    handleDayClick(day.id);
                                                }}
                                            >
                                                {day.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        {currentEvent?.truck && (
                            <>
                                <label className='calendarRequest__main__form__label'>
                                    Camión:
                                </label>
                                <Select
                                    showSearch
                                    placeholder='Selecciona un gestor'
                                    optionFilterProp='label'
                                    onChange={value => onChange(value, 'truck')}
                                    options={trucks}
                                    value={formData.truck}
                                />
                                <label className='calendarRequest__main__form__label'>
                                    Usuario gestor:
                                </label>
                                <Select
                                    showSearch
                                    placeholder='Selecciona un gestor'
                                    optionFilterProp='label'
                                    onChange={value =>
                                        onChange(value, 'gestorUser')
                                    }
                                    options={gestorUsers}
                                    value={formData.gestorUser}
                                />
                                <label className='calendarRequest__main__form__label'>
                                    Nota (opcional):
                                </label>
                                <Input
                                    placeholder='Ingresa una nota'
                                    count={{
                                        show: true,
                                        max: 100
                                    }}
                                    value={formData.note}
                                    onChange={e =>
                                        onChange(e.target.value, 'note')
                                    }
                                />
                            </>
                        )}
                        {showAlert && (
                            <div className='calendarRequest__main__form__alert'>
                                Por favor, completa todos los campos.
                            </div>
                        )}
                        <div className='calendarRequest__main__form__btns'>
                            <button
                                className={`button button-body--cancel subtitle`}
                                onClick={e => {
                                    e.preventDefault(); // Prevenir el comportamiento por defecto (si es necesario)
                                    resetForm(); // Llamar a la función para resetear el formulario
                                }}
                            >
                                Cancelar
                            </button>
                            <button
                                className={`button button-body--create subtitle`}
                                /*  htmlType='submit' */
                            >
                                Guardar
                            </button>
                        </div>
                    </form>
                </ConfigProvider>
            </div>
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default CreateEvent;
