import { useState, useEffect } from 'react';
import { startOfMonth, endOfMonth, startOfWeek, addDays } from 'date-fns';
import './calendar-mini.scss';
import { Link } from 'react-router-dom';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { useAppSelector } from '../../../../../store/hooks';
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { ApiCalendarByProperties } from '../../../../../config/service';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';

const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
];
const days = [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo'
]; // Ajustado para empezar el lunes

const CalendarMini = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const today = new Date(); // Día actual

    const [dates, setDates] = useState<any[]>([]);
    const [calendar] = useState<any>({
        month: today.getMonth(),
        year: today.getFullYear()
    });
    const [eventDates, setEventDates] = useState<string[]>([]);

    const CallData = async () => {
        let gestor: string | null = null;
        const rolesList = userData.roles.map(role => role.id);

        for (const role of userData.roles) {
            if (role.gestorData) {
                gestor = role.gestorData.id;
                break;
            }
        }

        const date = new Date().toISOString().slice(0, 7); // yyyy-mm

        const { data: calendars } = await GetData(
            ApiCalendarByProperties,
            HTTP_METHODS.POST,
            { userId: userData.id, rolesList, date, gestor }
        );

        const eventsByGestor = calendars.flatMap(calendar =>
            calendar.calendarDetails
                .filter(
                    calendarDetail => calendar.category === 'Retiros por gestor'
                ) // Filtrar por categoría
                .map(calendarDetail => {
                    const date = new Date(calendarDetail.date);
                    return {
                        date: `${date.getFullYear()}-${String(
                            date.getMonth() + 1
                        ).padStart(2, '0')}-${String(date.getDate()).padStart(
                            2,
                            '0'
                        )}`,
                        category: calendar.category
                    };
                })
        );

        setEventDates(eventsByGestor.map(event => event.date));
    };

    useEffect(() => {
        CallData();
    }, []);

    const generateCalendar = (month: number, year: number) => {
        const start = startOfWeek(startOfMonth(new Date(year, month)), {
            weekStartsOn: 1
        }); // Semana inicia el lunes
        const end = startOfWeek(endOfMonth(new Date(year, month)), {
            weekStartsOn: 1
        });

        const weeks: any = [];
        let currentDate = start;

        while (currentDate <= end) {
            const week: any = [];
            for (let i = 0; i < 7; i++) {
                week.push({
                    date: currentDate.getDate(),
                    month: currentDate.getMonth(),
                    year: currentDate.getFullYear(),
                    isCurrentMonth: currentDate.getMonth() === month // Solo días del mes actual
                });
                currentDate = addDays(currentDate, 1);
            }
            weeks.push(week);
        }
        return weeks;
    };

    useEffect(() => {
        const dates = generateCalendar(calendar.month, calendar.year);
        setDates(dates);
    }, [calendar.month, calendar.year]);

    const formattedSelectedDate = `${
        days[(today.getDay() + 6) % 7]
    } ${today.getDate()}`; // Ajusta el índice para iniciar desde lunes

    return (
        <div className='calendar-mini subtitle'>
            <Link className='calendar-mini__header' to='/panel/calendar'>
                <div className='calendar-mini__header__day subsubtitle'>
                    {formattedSelectedDate}
                </div>
                <div className='calendar-mini__header__month'>
                    {months[calendar.month]} / {calendar.year}
                </div>
            </Link>

            <div className='calendar-mini__content'>
                <div className='calendar-mini__content__tableContainer'>
                    <table className='calendar-mini__content__tableContainer__table'>
                        <tbody className='calendar-mini__content__tableContainer__table__body'>
                            <tr className='calendar-mini__content__tableContainer__table__body__row'>
                                {days.map(day => (
                                    <td
                                        className='calendar-mini__content__tableContainer__table__body__row__item'
                                        key={day}
                                    >
                                        <div className='calendar-mini__content__tableContainer__table__body__row__item__link'>
                                            {day.slice(0, 1)}{' '}
                                            {/* Solo dos letras del día */}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            {dates.map((week, index) => (
                                <tr
                                    className='calendar-mini__content__tableContainer__table__body__row'
                                    key={index}
                                >
                                    {week.map((each, idx) => {
                                        const isToday =
                                            today.getDate() === each.date &&
                                            today.getMonth() === each.month &&
                                            today.getFullYear() === each.year;

                                        const currentDate = `${
                                            each.year
                                        }-${String(
                                            parseInt(each.month) + 1
                                        ).padStart(2, '0')}-${String(
                                            each.date
                                        ).padStart(2, '0')}`;

                                        return (
                                            <td
                                                className={`calendar__content__tableContainer__table__body__row__item ${
                                                    each.isCurrentMonth
                                                        ? ''
                                                        : 'day-outside-month'
                                                }`}
                                                key={idx}
                                            >
                                                <Link
                                                    to='/panel/calendar'
                                                    className='calendar-mini__content__tableContainer__table__body__row__item__link'
                                                >
                                                    <div
                                                        className={`${
                                                            isToday
                                                                ? 'calendar-mini__content__tableContainer__table__body__row__item__link__dayActual'
                                                                : 'calendar-mini__content__tableContainer__table__body__row__item__link__day'
                                                        }`}
                                                    >
                                                        {each.date}
                                                    </div>
                                                    <div className='calendar-mini__content__tableContainer__table__body__row__item__link__events'>
                                                        {eventDates.includes(
                                                            currentDate
                                                        ) && (
                                                            <div className='calendar-mini__content__tableContainer__table__body__row__item__link__events__gestor'></div>
                                                        )}
                                                    </div>
                                                </Link>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default CalendarMini;
