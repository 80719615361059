import './create-calendar.scss';
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';
import CreateEvent from './kind/createEvent';
import CalendarRequest from './kind/calendarRequest';
import { IEventInfo } from '../../calendar';

interface Props {
    onClose: () => void;
    onRefresh?: () => void;
    currentEvent?: IEventInfo | null;
}

const CreateCalendar = ({ onClose, onRefresh, currentEvent }: Props) => {
    const { userData, defaultProfile }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const selectedRole = roles[defaultProfile].name;
    return (
        <div className='calendarRequest'>
            <div className={`calendarRequest__main`}>
                {selectedRole.includes('BZero') ||
                selectedRole.includes('Gestor') ? (
                    <CreateEvent
                        onClose={onClose}
                        onRefresh={onRefresh}
                        currentEvent={currentEvent}
                    />
                ) : (
                    <CalendarRequest onClose={onClose} />
                )}
            </div>
        </div>
    );
};

export default CreateCalendar;
