import './register.scss';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
//  <--COMPONENTS--> //
import { Container } from '../../components/styles';
import FormBuilder from '../../components/formBuilder/formBuilder';
import LogoComponent from '../../components/logo/logo';
/* import GoogleAuth from '../login/auth-components/google';
import MicrosoftAuth from '../login/auth-components/microsoft'; */
//  <--HOOKS--> //
import { useToastHook } from '../../store/slices/toast/toast.hook';
import { useToast } from '../../config/hooks/useToast';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
import { appCookie, Logout } from '../../config/utils/Auth';
import { RemoveCookie } from '../../config/utils/Cookie';
/* import { useCloseSession } from '../../config/hooks/useCloseSession/closeSession'; */
import useWindowDimensions from '../../config/hooks/useWindowDimentions';
//  <--INTERFACE--> //
import {
    RegisterFormValues,
    RegisterFormCreateInterface
} from '../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';
//  <--SERVICES--> //
import { ApiInvitationId, ApiInvitationRegister } from '../../config/service';
//  <--OTHERS--> //
import { lang } from '../langs';
import { FormCreateUser } from './form';
import '../../scss/global/global.scss';

const Registration = () => {
    /* const { SaveDataSections } = useCloseSession(); */
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { ToastConfig } = useToastHook();
    const { Colors } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    // const { id } = useParams();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const fieldRef = useRef(null);
    const {
        [defaultLang as keyof typeof lang]: {
            pageRegister: { errorPassvalidate, loginMessage, login }
        }
    } = lang;
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [inviteId, setInviteId] = useState<string>('');
    const [entity, setEntity] =
        useState<RegisterFormCreateInterface>(RegisterFormValues);
    /* const [formStructure, setFormStructure] =
        useState<FormBuilderFields[]>(FormCreateUser); */
    const [successCleanForm, setSuccessCleanForm] = useState<boolean>(true);
    const navigate = useNavigate();
    const OnLogout = async () => {
        RemoveCookie(appCookie);
        RemoveCookie(process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE);
        setTimeout(() => {
            Logout(navigate);
        }, 100);
    };
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setInviteId(id);
        }
    }, [id]);

    /**
     * @description CallData function, call the api to get the data of the entity and set the data in state
     */
    const CallData = async () => {
        const response = await GetData(
            ApiInvitationId(inviteId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        const initialData = RegisterFormValues;
        initialData.email = data.email;
        initialData.role = data.role.name;
        initialData.property = data.property ? data.property.name : '    ';
        setEntity(initialData);
        setReadyForm(true);
        if (!status) {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description this effect check that inviteId is not empty and if it changes, if not empty, call the function to CallData
     */
    useEffect(() => {
        if (inviteId !== '') {
            CallData();
        }
    }, [inviteId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async values => {
        setSuccessCleanForm(false);
        if (values.password !== values.newPasswordConfirm) {
            ToastConfig({
                message: errorPassvalidate,
                color: Colors.Warning
            });
            return;
        }
        values.inviteId = id;
        values.roledesc = values.role;
        const response = await GetData(
            ApiInvitationRegister,
            HTTP_METHODS.POST,
            values
        );
        const { message, status } = response;

        if (status) {
            ToastConfig({
                message,
                color: Colors.Success
            });
            await OnLogout();
            navigate('/login');
        } else {
            ToastConfig({
                message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    /* const successGoogle = data => {
        const newStructure = formStructure;
        newStructure[0].disabled = true;
        newStructure[1].disabled = true;
        newStructure[2].disabled = true;
        newStructure[7].disabled = true;
        newStructure[8].disabled = true;
        newStructure[7].show = false;
        newStructure[8].show = false;
        setFormStructure([...newStructure]);
        setEntity({
            ...entity,
            ...{
                email: data.email,
                name: data.given_name,
                lastName: data.family_name,
                isOauth: true,
                isGoogle: true,
                oauthId: data.sub,
                password: 'pryma',
                newPasswordConfirm: 'pryma'
            }
        });
    };

    const errorGoogle = () => {
        console.log('Login Failed');
    };

    const successMs = data => {
        const newStructure = formStructure;
        newStructure[0].disabled = true;
        newStructure[1].disabled = true;
        newStructure[2].disabled = true;
        newStructure[7].disabled = true;
        newStructure[8].disabled = true;
        newStructure[7].show = false;
        newStructure[8].show = false;
        setFormStructure([...newStructure]);
        setEntity({
            ...entity,
            ...{
                email: data.account.username,
                name: data.givenName,
                lastName: data.surname,
                isOauth: true,
                isMs: true,
                oauthId: data.account.localAccountId,
                password: 'pryma',
                newPasswordConfirm: 'pryma'
            }
        });
    };

    const errorMs = () => {
        console.log('Login Failed');
    }; */

    return (
        <div className='register_page'>
            {!isMobile && (
                <div className='register_page__item left-register'>
                    <Container className='_register'>
                        <LogoComponent image='PRYMA_LOGO_BLANCO.png' />
                        {LoadingData && <LoaderElement />}
                    </Container>
                    <div className='register-logo-inferior'>
                        {/* <div className='poweredby-text'>Powered by</div> */}
                        <LogoComponent image='BZERO_LOGO_BLANCO.svg' />
                    </div>
                </div>
            )}
            <div className='register_page__item right-register'>
                <div className='register_page__item__content'>
                    <div className='register_page__item__content__title'>
                        <img
                            className='register_page__item__content__title__img'
                            src='/svg-icons/SettingsIconG.svg'
                        />
                        <div className='register_page__item__content__title__text title'>
                            Datos Personales
                        </div>
                    </div>
                    <div className='register_page__item__content__form'>
                        {readyForm && (
                            <FormBuilder
                                initialValues={
                                    entity?.email ? entity : RegisterFormValues
                                }
                                buttons={FormButtons}
                                lang={defaultLang}
                                formStructure={
                                    FormCreateUser /* formStructure */
                                }
                                focusOn={fieldRef}
                                config={{ noClearButton: false }}
                                onSuccessCleanForm={successCleanForm}
                            ></FormBuilder>
                        )}
                        {/* {readyForm && (
                            <div className='register_page__item__content__form__oauth'>
                                <GoogleAuth
                                    onSuccess={successGoogle}
                                    onError={errorGoogle}
                                    textType='signup_with'
                                />
                                <MicrosoftAuth
                                    onSuccess={successMs}
                                    onError={errorMs}
                                />
                            </div>
                        )} */}
                    </div>
                    <div className='register_page__item__content__message subtitle'>
                        <span>{loginMessage} </span>
                        <Link
                            to={`/login/${id}`}
                            className='register_page__item__content__message__link subtitle'
                        >
                            {login}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;
