import { useEffect, useRef, useState } from 'react';
import {
    TableInterface,
    EditableTypesValues,
    TableEditableTypesValues,
    IsEditingInterface,
    DataRowEditedInterface
} from './interface/table.interface';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import './table.scss';
import { ConfigProvider, Checkbox, Popover, Tooltip } from 'antd';
import {
    FilterOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined
} from '@ant-design/icons';

const TableEditable = ({
    data,
    headers,
    actionsButtons,
    type,
    highlight,
    saveData,
    onRefresh,
    onSaveChange,
    changeDate,
    period,
    /* selectRegisters, */
    onChangeSelected
}: TableInterface) => {
    useEffect(() => {
        if (data && data.length > 0) {
            const regs: any[] = data;
            regs.sort((a, b) => {
                const dateA = parseInt(a.date);
                const dateB = parseInt(b.date);

                if (dateA < dateB) {
                    return -1;
                } else if (dateA > dateB) {
                    return 1;
                } else {
                    return 0;
                }
            });
            for (const el of regs) {
                el.isEdit = false;
                el.rowEdited = false;
                el.edited_truckId = false;
                el.edited_finalDUId = false;
                el.edited_managementId = false;
                el.edited_treatmentId = false;
                el.edited_weight = false;
                el.weight = parseFloat(el.weight).toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                });
                el.show = Object.keys(filters).every(field => {
                    if (filters[field].length === 0) {
                        return true; // No hay filtros para este campo, así que no filtra por este campo
                    }
                    return filters[field].includes(el[field]);
                });
            }
            setRegisters(regs);

            const uniqueDates: string[] = Array.from(
                new Set(regs.map(register => register.date))
            );
            const uniqueProperties: string[] = Array.from(
                new Set(regs.map(register => register.propertyName))
            );
            const uniqueGestors: string[] = Array.from(
                new Set(regs.map(register => register.gestorName))
            );
            const uniqueMaterials: string[] = Array.from(
                new Set(regs.map(register => register.material))
            );
            setFilterOptions({
                date: uniqueDates,
                propertyName: uniqueProperties,
                gestorName: uniqueGestors,
                material: uniqueMaterials
            });
        } else {
            setRegisters([]);
        }
    }, [data]);

    useEffect(() => {
        if (highlight?.show) {
            let changeData = registers;
            registers.map((element, index) => {
                if (
                    (!element.rowEdited && element.truckId === null) ||
                    element.finalDUId === null ||
                    element.managementId === null ||
                    element.treatmentId === null
                ) {
                    element.rowEdited = true;
                    if (
                        element.truck_autofill === 0 &&
                        element.truckId === null
                    ) {
                        element.fillpending = true;
                    } else {
                        element.fillpending = false;
                    }
                    if (
                        element.fdu_autofill === 0 &&
                        element.finalDUId === null
                    ) {
                        element.fillpending = true;
                    } else {
                        element.fillpending = false;
                    }
                    if (
                        element.management_autofill === 0 &&
                        element.managementId === null
                    ) {
                        element.fillpending = true;
                    } else {
                        element.fillpending = false;
                    }
                    if (
                        element.treatment_autofill === 0 &&
                        element.treatmentId === null
                    ) {
                        element.fillpending = true;
                    } else {
                        element.fillpending = false;
                    }
                    changeData.splice(index, 1);
                    changeData.splice(index, 0, element);
                    changeData = [...changeData, element];
                    changeData.splice(changeData.length - 1, 1);
                    setRegisters(changeData);
                }

                if (element.truckId === null) {
                    element.truckId = element.dtm_truckId;
                    element.truckPatent = element.dtm_truckPatent;
                }

                if (element.finalDUId === null) {
                    element.finalDUId = element.mdt_finalDUId;
                    element.finalDUName = element.mdt_finalDUName;
                }

                if (element.managementId === null) {
                    element.managementId = element.mdt_management;
                    element.managementName = element.mdt_managementName;
                }

                if (element.treatmentId === null) {
                    element.treatmentId = element.mdt_treatmentId;
                    element.treatmentName = element.mdt_treatmentName;
                }

                if (
                    element.truck_autofill === null &&
                    element.truckId !== null
                ) {
                    element.truck_autofill = true;
                }
                if (
                    element.fdu_autofill === null &&
                    element.finalDUId !== null
                ) {
                    element.fdu_autofill = true;
                }
                if (
                    element.management_autofill === null &&
                    element.managementId !== null
                ) {
                    element.management_autofill = true;
                }
                if (
                    element.treatment_autofill === null &&
                    element.treatmentId !== null
                ) {
                    element.treatment_autofill = true;
                }
                return false;
            });
        }
    }, [highlight]);

    const [registers, setRegisters] = useState<any>([]);
    const [editing, setEditing] = useState<IsEditingInterface>({
        editing: false,
        element: null
    });
    const [editedData, setEditedData] = useState<DataRowEditedInterface[]>([]);
    const [open, setOpen] = useState(false);
    const [filterOptions, setFilterOptions] = useState({
        date: [] as string[],
        propertyName: [] as string[],
        gestorName: [] as string[],
        material: [] as string[]
    });
    const [filters, setFilters] = useState({
        date: [] as string[],
        propertyName: [] as string[],
        gestorName: [] as string[],
        material: [] as string[]
    });
    const datePickerRef = useRef<HTMLDivElement | null>(null);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    /* const [selectAll, setSelectAll] = useState<boolean>(true); */

    useEffect(() => {
        setEditedData([]);
        setEditing({
            editing: false,
            element: null
        });
    }, [onRefresh]);

    const OnEditChangeValue = (event, element, index, header, idx = 0) => {
        const value = event?.target?.value;
        const indexSelected = event?.nativeEvent?.target?.selectedIndex;
        const valueName = event?.nativeEvent?.target[indexSelected]?.text;
        const dataEdited = editedData;
        const obj = {
            id: value,
            text: valueName ?? value
        };
        const exist = dataEdited.find(element => element.id === obj.id);
        if (
            header.dependsThisListDataKey &&
            header.dependsThisListDataKey !== ''
        ) {
            if (
                headers[header.dependsThisListDataIndexColumn].editableListData
            ) {
                if (headers[idx].editableListData !== undefined) {
                    const sel = headers[idx]?.editableListData?.find(
                        (item: any) => item.id === obj.id
                    );
                    const newList = sel
                        ? sel[header.dependsThisListDataKey]
                        : [];
                    headers[
                        header.dependsThisListDataIndexColumn
                    ].editableListData = newList;
                }
            }
        }
        if (!exist) {
            ChangeStateData(
                registers,
                header.dataField,
                value,
                index,
                element,
                header.relatedDatafield,
                valueName ?? value,
                header.fieldAutofill
            );
        }
    };

    const SetEditRow = (item, index) => {
        const idx = 6;
        const header = headers[idx];
        const id = item.managementId;
        if (
            header.dependsThisListDataKey &&
            header.dependsThisListDataKey !== ''
        ) {
            if (
                header.dependsThisListDataIndexColumn &&
                headers[header.dependsThisListDataIndexColumn].editableListData
            ) {
                if (headers[idx].editableListData !== undefined) {
                    const sel = headers[idx]?.editableListData?.find(
                        (item: any) => item.id === id
                    );
                    const newList = sel
                        ? sel[header.dependsThisListDataKey]
                        : [];
                    headers[
                        header.dependsThisListDataIndexColumn
                    ].editableListData = newList;
                }
            }
        }
        setEditing({ ...editing, editing: true, element: index });
        ChangeStateData(registers, 'isEdit', true, index, item);
    };

    const SaveEditRow = (element, index) => {
        const array = editedData;
        if (!array.includes(element.id)) {
            array.push(element);
        }
        setEditedData(array);

        let changeData = registers;
        element.isEdit = false;
        element.autofilled = 0;
        element.rowEdited = true;
        changeData.splice(index, 1);
        changeData.splice(index, 0, element);
        changeData = [...changeData, element];
        changeData.splice(changeData.length - 1, 1);
        setRegisters(changeData);
        setEditing({ ...editing, editing: false, element: index });
    };

    const ChangeStateData = (
        originData,
        keyMutate,
        value,
        index,
        row,
        relatedDatafield = null,
        valueName = null,
        fieldAutofill = null
    ) => {
        let changeData = originData;
        const selected = changeData.find(x => x.id === row.id);
        selected[keyMutate] = value;
        if (relatedDatafield !== null && valueName !== null) {
            selected[relatedDatafield] = value;
            selected[keyMutate] = valueName;
            selected[`edited_${relatedDatafield}`] = true;
            if (fieldAutofill) {
                selected[fieldAutofill] = false;
            }
        }
        changeData.splice(index, 1);
        changeData.splice(index, 0, selected);
        changeData = [...changeData, selected];
        changeData.splice(changeData.length - 1, 1);
        setRegisters(changeData);
    };

    const handleCheckboxChange = (checked, value, dataField) => {
        // Actualizar el estado de los filtros seleccionados
        setFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [dataField]: checked
                    ? [...prevFilters[dataField], value] // Agrega el valor si está marcado
                    : prevFilters[dataField].filter(item => item !== value) // Elimina el valor si está desmarcado
            };
            return updatedFilters;
        });
    };

    /* const handleSelectCheckboxChange = (checked, id) => {
        setSelectedIds(prevSelectedIds => {
            if (checked) {
                // Agregar el ID si se ha seleccionado
                return [...prevSelectedIds, id];
            } else {
                // Eliminar el ID si se ha deseleccionado
                return prevSelectedIds.filter(selectedId => selectedId !== id);
            }
        });
    }; */

    /*  const handleSelectAllChange = checked => {
        setSelectAll(checked);
        if (checked) {
            // Agregar todos los IDs de los registers con show: true
            const idsToAdd = registers
                .filter(register => register.show)
                .map(register => register.id);

            setSelectedIds(prevSelectedIds => {
                // Combina los arrays y elimina duplicados usando `filter`
                return prevSelectedIds.concat(
                    idsToAdd.filter(id => !prevSelectedIds.includes(id))
                );
            });
        } else {
            // Eliminar todos los IDs de los registers con show: true
            const idsToRemove = registers
                .filter(register => register.show)
                .map(register => register.id);

            setSelectedIds(prevSelectedIds =>
                prevSelectedIds.filter(id => !idsToRemove.includes(id))
            );
        }
    }; */

    useEffect(() => {
        onChangeSelected(selectedIds);
    }, [selectedIds]);

    useEffect(() => {
        const updatedRegisters = registers.map(register => {
            // Determinar si el registro debe ser visible basándose en los filtros seleccionados
            const shouldShow = Object.keys(filters).every(field => {
                if (filters[field].length === 0) {
                    return true; // No hay filtros para este campo, así que no filtra por este campo
                }
                return filters[field].includes(register[field]);
            });

            return { ...register, show: shouldShow };
        });
        setRegisters([...updatedRegisters]);
        const filteredIds = updatedRegisters
            .filter(register => register.show)
            .map(register => register.id);
        setSelectedIds(filteredIds);
    }, [filters]);

    useEffect(() => {
        if (onSaveChange) {
            saveData(registers);
        }
    }, [onSaveChange]);

    const handleClickOutside = event => {
        if (
            datePickerRef.current &&
            !datePickerRef?.current?.contains(event.target)
        ) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    return (
        <>
            <div className='months'>
                <button
                    className='months__btn subtitle'
                    onClick={() => changeDate(-1)}
                >
                    <DoubleLeftOutlined style={{ color: '#C4C3C5' }} />
                    Mes anterior
                </button>
                <div className='months__title'>{period}</div>
                <button
                    className='months__btn subtitle'
                    onClick={() => changeDate(1)}
                >
                    Mes siguiente
                    <DoubleRightOutlined style={{ color: '#C4C3C5' }} />
                </button>
            </div>
            <div className='table'>
                <table className='table__editable'>
                    <thead className='table__editable__thead'>
                        <tr className='table__editable__thead__tr'>
                            {headers.map(item => (
                                <th
                                    key={item.text}
                                    className={`table__editable__thead__tr__th ${item.text} subtitle`}
                                >
                                    {item.options ? (
                                        <span
                                            className='table__editable__thead__tr__th__text'
                                            style={{
                                                width: '100%',
                                                display: 'block',
                                                textAlign: item.options.align,
                                                fontWeight: item.options.strong
                                                    ? 'bold'
                                                    : 'normal'
                                            }}
                                        >
                                            {item.text}
                                        </span>
                                    ) : (
                                        <div className='table__editable__thead__tr__th__text'>
                                            {item.text}
                                            {(item.dataField === 'date' ||
                                                item.dataField ===
                                                    'propertyName' ||
                                                item.dataField ===
                                                    'gestorName' ||
                                                item.dataField ===
                                                    'material') && (
                                                <div className='table__editable__thead__tr__th__text__btn'>
                                                    <Popover
                                                        placement='bottom'
                                                        trigger='click'
                                                        content={
                                                            <div className='table__editable__thead__tr__th__text__popover subtitle'>
                                                                {filterOptions[
                                                                    item
                                                                        .dataField
                                                                ]?.map(
                                                                    (
                                                                        value,
                                                                        index
                                                                    ) => (
                                                                        <div
                                                                            className='table__editable__thead__tr__th__text__popover__item'
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <ConfigProvider
                                                                                theme={{
                                                                                    components:
                                                                                        {
                                                                                            Checkbox:
                                                                                                {
                                                                                                    colorPrimary:
                                                                                                        '#a2a2a3',
                                                                                                    colorBorder:
                                                                                                        '#a2a2a3',
                                                                                                    colorPrimaryHover:
                                                                                                        '#a2a2a3'
                                                                                                }
                                                                                        }
                                                                                }}
                                                                            >
                                                                                <Checkbox
                                                                                    className={`table__editable__thead__tr__th__text__popover__item__checkbox`}
                                                                                    onChange={e =>
                                                                                        handleCheckboxChange(
                                                                                            e
                                                                                                .target
                                                                                                .checked,
                                                                                            value,
                                                                                            item.dataField
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </ConfigProvider>
                                                                            <div
                                                                                className={`table__editable__thead__tr__th__text__popover__item__label`}
                                                                            >
                                                                                {
                                                                                    value
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        }
                                                    >
                                                        <FilterOutlined
                                                            style={{
                                                                color: '#fff'
                                                            }}
                                                        />
                                                    </Popover>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </th>
                            ))}
                            <th className='table__editable__thead__tr__th editColumn'>
                                {/* {selectRegisters && (
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Checkbox: {
                                                    colorPrimary: '#a2a2a3',
                                                    colorBorder: '#a2a2a3',
                                                    colorPrimaryHover: '#a2a2a3'
                                                }
                                            }
                                        }}
                                    >
                                        <Checkbox
                                            checked={selectAll}
                                            onChange={e =>
                                                handleSelectAllChange(
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    </ConfigProvider>
                                )} */}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {registers
                            .filter(item => item.show)
                            .map((item: any, index) => {
                                return (
                                    <tr
                                        key={`table_EDITABLE_BODY_${index}`}
                                        className={`table__editable__tbody__tr ${
                                            highlight !== null &&
                                            item[highlight?.field] === 1 &&
                                            highlight.show
                                                ? highlight.addClass
                                                : ''
                                        }`}
                                    >
                                        {headers.map((header, idx) => {
                                            let iterableList;
                                            if (
                                                header.filterBy &&
                                                header.filterTo
                                            ) {
                                                iterableList =
                                                    header.editableListData?.filter(
                                                        (headEdit: any) =>
                                                            headEdit.gestor ===
                                                            item.gestorId
                                                    );
                                            } else {
                                                iterableList =
                                                    header.editableListData;
                                            }
                                            const nameCol =
                                                header.dataField === null
                                                    ? 'id'
                                                    : header.dataField;
                                            let addedclases = '';
                                            switch (header.fieldAutofill) {
                                                case 'truck_autofill':
                                                    if (
                                                        item.truck_autofill ===
                                                        1
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' auto_ses_ant';
                                                    } else if (
                                                        item[nameCol] !==
                                                            null &&
                                                        !item.isEdit
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' man_ses_ant';
                                                    }
                                                    break;
                                                case 'fdu_autofill':
                                                    if (
                                                        item.fdu_autofill === 1
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' auto_ses_ant';
                                                    } else if (
                                                        item[nameCol] !==
                                                            null &&
                                                        !item.isEdit
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' man_ses_ant';
                                                    }
                                                    break;
                                                case 'management_autofill':
                                                    if (
                                                        item.management_autofill ===
                                                        1
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' auto_ses_ant';
                                                    } else if (
                                                        item[nameCol] !==
                                                            null &&
                                                        !item.isEdit
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' man_ses_ant';
                                                    }
                                                    break;
                                                case 'treatment_autofill':
                                                    if (
                                                        item.treatment_autofill ===
                                                        1
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' auto_ses_ant';
                                                    } else if (
                                                        item[nameCol] !==
                                                            null &&
                                                        !item.isEdit
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' man_ses_ant';
                                                    }
                                                    break;
                                                case 'weight_autofill':
                                                    if (
                                                        item.qtyGr_edited ===
                                                            1 &&
                                                        !item.isEdit
                                                    ) {
                                                        addedclases = '';
                                                        addedclases =
                                                            ' man_ses_ant';
                                                    }
                                                    break;
                                            }

                                            const exist = editedData.find(
                                                elm => elm.id === item.id
                                            );
                                            if (
                                                exist &&
                                                header.relatedDatafield &&
                                                item[header.relatedDatafield] &&
                                                item[
                                                    `edited_${header.relatedDatafield}`
                                                ]
                                            ) {
                                                addedclases = '';
                                                addedclases = ' man_ses_act';
                                            }

                                            if (
                                                header.fieldAutofill &&
                                                header.dataFieldIsAutoFill
                                            ) {
                                                switch (header.fieldAutofill) {
                                                    case 'truck_autofill':
                                                        if (
                                                            item.truck_autofill &&
                                                            item.rowEdited
                                                        ) {
                                                            addedclases = '';
                                                            addedclases =
                                                                ' auto_ses_act';
                                                        }
                                                        break;
                                                    case 'fdu_autofill':
                                                        if (
                                                            item.fdu_autofill &&
                                                            item.rowEdited
                                                        ) {
                                                            addedclases = '';
                                                            addedclases =
                                                                ' auto_ses_act';
                                                        }
                                                        break;
                                                    case 'management_autofill':
                                                        if (
                                                            item.management_autofill &&
                                                            item.rowEdited
                                                        ) {
                                                            addedclases = '';
                                                            addedclases =
                                                                ' auto_ses_act';
                                                        }
                                                        break;
                                                    case 'treatment_autofill':
                                                        if (
                                                            item.treatment_autofill &&
                                                            item.rowEdited
                                                        ) {
                                                            addedclases = '';
                                                            addedclases =
                                                                ' auto_ses_act';
                                                        }
                                                        break;
                                                }
                                            }

                                            return (
                                                <td
                                                    key={`table_EDITABLE_BODY_${nameCol}_${idx}`}
                                                    className={
                                                        header.isEditable
                                                            ? `table__editable__tbody__tr__td ${addedclases}`
                                                            : `table__editable__tbody__tr__td`
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        {item.isEdit ? (
                                                            !header.isEditable ? (
                                                                <Tooltip
                                                                    title={
                                                                        <span className='main_menu__grid-container__tooltip'>
                                                                            {
                                                                                item[
                                                                                    nameCol
                                                                                ]
                                                                            }
                                                                        </span>
                                                                    }
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    color='#fff'
                                                                >
                                                                    <span
                                                                        className={
                                                                            highlight?.show &&
                                                                            header.isEditable &&
                                                                            item[
                                                                                highlight
                                                                                    .field
                                                                            ] ===
                                                                                1
                                                                                ? 'table__editable__tbody__tr__td__span isEditable subtitle'
                                                                                : 'table__editable__tbody__tr__td__span subtitle'
                                                                        }
                                                                        style={{
                                                                            width: '100%',
                                                                            display:
                                                                                'block'
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        {
                                                                            item[
                                                                                nameCol
                                                                            ]
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                            ) : header.editableType ===
                                                              EditableTypesValues.text ? (
                                                                <input
                                                                    className='table__editable__tbody__tr__td__input'
                                                                    type='text'
                                                                    onChange={event => {
                                                                        OnEditChangeValue(
                                                                            event,
                                                                            item,
                                                                            index,
                                                                            header
                                                                        );
                                                                    }}
                                                                    defaultValue={
                                                                        item[
                                                                            nameCol
                                                                        ]
                                                                    }
                                                                />
                                                            ) : (
                                                                <select
                                                                    className='table__editable__tbody__tr__td__lst'
                                                                    onChange={event => {
                                                                        OnEditChangeValue(
                                                                            event,
                                                                            item,
                                                                            index,
                                                                            header,
                                                                            idx
                                                                        );
                                                                    }}
                                                                    defaultValue={
                                                                        header.relatedDatafield
                                                                            ? item[
                                                                                  header
                                                                                      .relatedDatafield
                                                                              ]
                                                                            : ''
                                                                    }
                                                                >
                                                                    <option value=''>
                                                                        Seleccionar
                                                                    </option>
                                                                    {iterableList.map(
                                                                        (
                                                                            itemData: any
                                                                        ) => {
                                                                            const textoLista =
                                                                                header.editableListDataKey
                                                                                    ? itemData[
                                                                                          header
                                                                                              .editableListDataKey
                                                                                      ]
                                                                                    : itemData.name;
                                                                            return (
                                                                                <option
                                                                                    key={`opt_${itemData.id}`}
                                                                                    value={
                                                                                        itemData.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        textoLista
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </select>
                                                            )
                                                        ) : item[nameCol]
                                                              ?.length > 10 ? (
                                                            <Tooltip
                                                                title={
                                                                    <span className='main_menu__grid-container__tooltip'>
                                                                        {/* {nameCol ===
                                                                'date'
                                                                    ? DateToString(
                                                                          item[
                                                                              nameCol
                                                                          ]
                                                                      )
                                                                    : item[
                                                                          nameCol
                                                                      ]} */}
                                                                        {
                                                                            item[
                                                                                nameCol
                                                                            ]
                                                                        }
                                                                    </span>
                                                                }
                                                                key={item.id}
                                                                color='#fff'
                                                            >
                                                                <span
                                                                    className={
                                                                        highlight?.show &&
                                                                        header.isEditable &&
                                                                        item[
                                                                            highlight
                                                                                .field
                                                                        ] === 1
                                                                            ? 'table__editable__tbody__tr__td__span isEditable  subtitle'
                                                                            : 'table__editable__tbody__tr__td__span  subtitle'
                                                                    }
                                                                    style={{
                                                                        width: '100%',
                                                                        display:
                                                                            'block'
                                                                    }}
                                                                >
                                                                    {/* {nameCol === 'date'
                                                                ? DateToString(
                                                                      item[
                                                                          nameCol
                                                                      ]
                                                                  )
                                                                : item[nameCol]} */}
                                                                    {
                                                                        item[
                                                                            nameCol
                                                                        ]
                                                                    }
                                                                </span>
                                                            </Tooltip>
                                                        ) : (
                                                            <span
                                                                className={
                                                                    highlight?.show &&
                                                                    header.isEditable &&
                                                                    item[
                                                                        highlight
                                                                            .field
                                                                    ] === 1
                                                                        ? 'table__editable__tbody__tr__td__span isEditable  subtitle'
                                                                        : 'table__editable__tbody__tr__td__span  subtitle'
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    display:
                                                                        'block'
                                                                }}
                                                            >
                                                                {item[nameCol]}
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                        <td
                                            className='table__editable__tbody__tr__td'
                                            style={{
                                                maxWidth: '100px',
                                                borderBottom: 'unset'
                                            }}
                                        >
                                            {type ===
                                                TableEditableTypesValues.inRow &&
                                                (editing.editing &&
                                                index === editing.element ? (
                                                    <SaveIcon
                                                        titleAccess='Grabar'
                                                        className='table__editable__tbody__tr__td__icon'
                                                        onClick={() => {
                                                            SaveEditRow(
                                                                item,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    /* selectRegisters ? (
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Checkbox: {
                                                                    colorPrimary:
                                                                        '#a2a2a3',
                                                                    colorBorder:
                                                                        '#a2a2a3',
                                                                    colorPrimaryHover:
                                                                        '#a2a2a3'
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <Checkbox
                                                            className='table__editable__tbody__tr__td__icon'
                                                            checked={selectedIds.includes(
                                                                item.id
                                                            )}
                                                            onChange={e =>
                                                                handleSelectCheckboxChange(
                                                                    e.target
                                                                        .checked,
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                    </ConfigProvider>
                                                ) : */ <EditIcon
                                                        titleAccess='Editar'
                                                        className='table__editable__tbody__tr__td__icon'
                                                        onClick={() => {
                                                            SetEditRow(
                                                                item,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                ))}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TableEditable;
