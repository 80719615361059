import './styles.scss';
import { SetStateAction, Dispatch, useState } from 'react';
import { IEventInfo } from '../calendar';
import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ConfigProvider, Radio, RadioChangeEvent } from 'antd';
import CreateCalendar from './create/create-calendar';
import AssignEventModal from './assignEventModal';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

interface IProps {
    open: boolean;
    handleClose: Dispatch<SetStateAction<void>>;
    onDeleteEvent: (all: boolean, event: string | undefined) => void;
    currentEvent: IEventInfo | null;
    color?: string; // Nueva propiedad opcional para el color del borde
    onRefresh: Dispatch<SetStateAction<void>>;
}

const EventInfoModal = ({
    open,
    handleClose,
    onDeleteEvent,
    currentEvent,
    color,
    onRefresh
}: IProps) => {
    const { userData, defaultProfile }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const selectedRole = roles[defaultProfile].name;
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [value, setValue] = useState<boolean>(false);
    const [editEvent, setEditEvent] = useState<boolean>(false);
    const [assignEvent, setAssignEvent] = useState<boolean>(false);
    const onClose = () => {
        handleClose();
        setEditEvent(false);
    };

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const formattedDate = currentEvent?.start
        ? currentEvent.start.toLocaleDateString('es-ES', {
              weekday: 'long', // "jueves"
              year: 'numeric', // "2024"
              month: 'long', // "noviembre"
              day: 'numeric' // "7"
          })
        : 'Fecha no disponible';

    const getRecurrenceMessage = recurrentDetail => {
        const days = recurrentDetail.days; // [1, 3, 5]
        const weeks = recurrentDetail.weeks; // 1 o 2

        // Mapeo de los números de días a sus nombres
        const diasDeLaSemana = {
            1: 'lunes',
            2: 'martes',
            3: 'miércoles',
            4: 'jueves',
            5: 'viernes',
            6: 'sábado',
            7: 'domingo'
        };

        // Filtrar los días seleccionados por su nombre
        const diasSeleccionados = days.map(day => diasDeLaSemana[day]);

        // Crear la base del mensaje con los días
        let mensaje = `Todos los ${diasSeleccionados.join(', ')}`;

        // Añadir la información de las semanas si es necesario
        if (weeks === 2) {
            mensaje += `, cada ${weeks} semanas`;
        }

        return mensaje;
    };

    // Si `open` es false, retornamos `null` para evitar renderizar nada
    if (!open) return null;

    return (
        <>
            <div className='eventInfoModal'>
                <div className={`eventInfoModal__main`}>
                    <div className='eventInfoModal__main__menu title'>
                        <EditOutlined
                            className='eventInfoModal__main__menu__icon'
                            onClick={() => setEditEvent(true)}
                        />
                        <DeleteOutlined
                            className='eventInfoModal__main__menu__icon'
                            onClick={() => setShowAlert(!showAlert)}
                        />
                        <CloseOutlined
                            className='eventInfoModal__main__menu__icon'
                            onClick={onClose}
                        />
                        <hr />
                    </div>
                    <div className='eventInfoModal__main__header title'>
                        <div className='eventInfoModal__main__header__title'>
                            <div
                                className='eventInfoModal__main__header__title__circle'
                                style={{
                                    backgroundColor: `${color ?? '#b64fc8'}` // Color del borde izquierdo
                                }}
                            />
                            <div className='eventInfoModal__main__header__title__text'>
                                {currentEvent?.title}
                            </div>
                        </div>
                        <div className='eventInfoModal__main__header__date subtitle'>
                            <div />
                            {formattedDate} - {currentEvent?.hour}
                        </div>
                        {currentEvent?.recurrentDetail && (
                            <div className='eventInfoModal__main__header__date subtitle'>
                                <div />
                                {getRecurrenceMessage(
                                    currentEvent?.recurrentDetail
                                )}
                            </div>
                        )}
                    </div>
                    <div className='eventInfoModal__main__content subtitle'>
                        <div className='eventInfoModal__main__content__item'>
                            <div className='eventInfoModal__main__content__item__title'>
                                Gestor:
                            </div>
                            <div className='eventInfoModal__main__content__item__detail'>
                                <div className='eventInfoModal__main__content__item__detail__imgContainer'>
                                    <img
                                        className='eventInfoModal__main__content__item__detail__imgContainer__img'
                                        src='/svg-icons/eventicon.svg'
                                    />
                                </div>
                                <div className='eventInfoModal__main__content__item__detail__text'>
                                    <div className='eventInfoModal__main__content__item__detail__text__name'>
                                        {currentEvent?.gestorName}
                                    </div>
                                    <div className='eventInfoModal__main__content__item__detail__text__info'>
                                        Contacto:{' '}
                                        {currentEvent?.gestorContactName} -{' '}
                                        {currentEvent?.gestorContactEmail}
                                    </div>
                                    <div className='eventInfoModal__main__content__item__detail__text__info'>
                                        {currentEvent?.description}
                                    </div>
                                    {currentEvent?.patent ? (
                                        <>
                                            <div className='eventInfoModal__main__content__item__detail__text__info'>
                                                Camión: {currentEvent?.patent}
                                            </div>

                                            <div className='eventInfoModal__main__content__item__detail__text__info'>
                                                Usuario:{' '}
                                                {currentEvent?.gestorUserName
                                                    ? currentEvent?.gestorUserName
                                                    : '-'}
                                            </div>
                                        </>
                                    ) : (
                                        (selectedRole.includes('BZero') ||
                                            selectedRole.includes(
                                                'Gestor'
                                            )) && (
                                            <button
                                                className='eventInfoModal__main__content__item__detail__text__button'
                                                onClick={() =>
                                                    setAssignEvent(true)
                                                }
                                            >
                                                Asignar camión y/o usuario
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='eventInfoModal__main__content__item'>
                            <div className='eventInfoModal__main__content__item__title'>
                                Inmueble:
                            </div>
                            <div className='eventInfoModal__main__content__item__detail'>
                                <div className='eventInfoModal__main__content__item__detail__imgContainer'>
                                    <img
                                        className='eventInfoModal__main__content__item__detail__imgContainer__img'
                                        src='/svg-icons/eventicon.svg'
                                    />
                                </div>
                                <div className='eventInfoModal__main__content__item__detail__text'>
                                    <div className='eventInfoModal__main__content__item__detail__text__name'>
                                        {currentEvent?.propertyName}
                                    </div>
                                    <div className='eventInfoModal__main__content__item__detail__text__info'>
                                        Dirección:{' '}
                                        {currentEvent?.propertyAddress}
                                    </div>
                                    <div className='eventInfoModal__main__content__item__detail__text__info'>
                                        Estado de almacenamiento: -
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='eventInfoModal__main__content subtitle'>
                        <div className='eventInfoModal__main__content__item'>
                            <div className='eventInfoModal__main__content__item__title'>
                                Nota:
                            </div>
                        </div>
                        {currentEvent?.note && (
                            <div className='eventInfoModal__main__content__item__detail'>
                                <div className='eventInfoModal__main__content__item__detail__text'>
                                    <div className='eventInfoModal__main__content__item__detail__text__name'>
                                        {currentEvent?.gestorUserName}
                                    </div>
                                    <div className='eventInfoModal__main__content__item__detail__text__note'>
                                        {currentEvent?.note}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {showAlert && (
                    <div className='event_alert_container'>
                        <div className='event_alert_container__main'>
                            <div className='event_alert_container__main__title title'>
                                <span> Eliminar registro </span>
                                <CloseOutlined
                                    className='eventInfoModal__main__menu__icon'
                                    onClick={() => setShowAlert(false)}
                                />
                            </div>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Radio: {
                                            colorPrimary: '#A2A2A3',
                                            colorBorder: '#A2A2A3',
                                            colorPrimaryHover: '#A2A2A3'
                                        }
                                    }
                                }}
                            >
                                <Radio.Group
                                    className='event_alert_container__main__text subtitle'
                                    onChange={onChange}
                                    value={value}
                                >
                                    <Radio className='subtitle' value={false}>
                                        Este evento
                                    </Radio>
                                    <Radio className='subtitle' value={true}>
                                        Este y los siguientes
                                    </Radio>
                                </Radio.Group>
                            </ConfigProvider>
                            <div className='event_alert_container__main__btns subtitle'>
                                <button
                                    className={`button button-body--delete-event subtitle`}
                                    onClick={() => {
                                        onDeleteEvent(value, currentEvent?._id);
                                        setShowAlert(false);
                                    }}
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {editEvent && (
                <CreateCalendar
                    onClose={onClose}
                    onRefresh={onRefresh}
                    currentEvent={currentEvent}
                />
            )}
            {assignEvent && (
                <AssignEventModal
                    handleClose={() => {
                        setAssignEvent(false);
                        onClose();
                    }}
                    onRefresh={onRefresh}
                    currentEvent={currentEvent}
                />
            )}
        </>
    );
};

export default EventInfoModal;
