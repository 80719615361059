import { useEffect, useState } from 'react';
import {
    DeleteFilled,
    EditFilled,
    PlusCircleFilled,
    SaveFilled
} from '@ant-design/icons';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import {
    ApiGarbageCostDetailUpdateAdd,
    ApiGarbageProratioUpdate,
    ApiGarbageCostDetailId
} from '../../../../config/service';

interface Props {
    data: any[];
    property: string;
    garbageCost: any;
    date: string;
    onRefresh: () => void;
}

const GarbageCostsDetail = ({
    data,
    property,
    garbageCost,
    date,
    onRefresh
}: Props) => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editedData, setEditedData] = useState<any[]>([]);
    const [newItem, setNewItem] = useState<any | null>(null);
    const [tempData, setTempData] = useState<any | null>(null);

    const handleEditClick = (index: number) => {
        setEditingIndex(index);
        setTempData({ ...editedData[index] }); // Guarda los datos actuales en tempData
    };

    const handleInputChange = (field: string, value: string) => {
        setTempData((prevData: any) => ({ ...prevData, [field]: value }));
    };

    const handleNewItemChange = (field: string, value: string) => {
        setNewItem((prevItem: any) => ({ ...prevItem, [field]: value }));
    };

    const handleSaveClick = (index: number | null) => {
        if (index !== null && tempData) {
            const updatedData = editedData.map((item, i) => {
                if (i === index) {
                    const isEdited =
                        !item.new &&
                        (item.description !== tempData.description ||
                            item.cost !== tempData.cost ||
                            item.detail !== tempData.detail);
                    return { ...tempData, edited: isEdited };
                }
                return item;
            });
            setEditedData(updatedData);
            setEditingIndex(null);
            setTempData(null);
        }
    };

    const handleNewItemSaveClick = () => {
        if (newItem) {
            const newItemWithFlags = {
                ...newItem,
                date: `${date.substring(0, 4)}-${date.substring(4, 6)}`,
                garbageCost,
                new: true,
                edited: false
            };
            setEditedData(prevData => [...prevData, newItemWithFlags]);
            setNewItem(null);
        }
    };

    const handleCancelClick = () => {
        setEditingIndex(null);
        setTempData(null);
        setNewItem(null);
        setEditedData(
            data.map(item => ({
                ...item,
                new: false,
                edited: false,
                delete: false
            }))
        );
    };

    const SaveAll = async () => {
        const itemsToDelete = editedData.filter(item => item.delete);
        const itemsToUpdate = editedData.filter(
            item => item.edited && !item.delete
        );
        const newItems = editedData.filter(item => item.new && !item.delete);

        // Variable para controlar si se debe actualizar el prorateo
        let updateProratio = false;

        // Eliminar ítems marcados para eliminar
        for (const item of itemsToDelete) {
            if (!item.new) {
                // Solo eliminar si no es un ítem nuevo (ya guardado en el backend)
                const deleteResponse = await GetData(
                    ApiGarbageCostDetailId(item.id),
                    HTTP_METHODS.DELETE
                );
                if (deleteResponse.status) {
                    updateProratio = true; // Si la eliminación es exitosa, se debe actualizar el prorateo
                }
            }
        }

        // Guardar ítems nuevos o editados
        if (itemsToUpdate.length > 0 || newItems.length > 0) {
            const response = await GetData(
                ApiGarbageCostDetailUpdateAdd,
                HTTP_METHODS.POST,
                [...itemsToUpdate, ...newItems]
            );

            if (response.status) {
                updateProratio = true; // Si se guardan cambios, se debe actualizar el prorateo
            }
        }

        // Si hay necesidad de actualizar el prorateo, haz la llamada correspondiente
        if (updateProratio) {
            const values = {
                garbageCost,
                date: `${date.substring(0, 4)}-${date.substring(4, 6)}`
            };
            const response2 = await GetData(
                ApiGarbageProratioUpdate,
                HTTP_METHODS.POST,
                values
            );

            if (response2.status) {
                onRefresh(); // Refrescar la lista si la actualización es exitosa
            }
        }

        // Actualizar el estado para reflejar los cambios
        setEditedData(editedData.filter(item => !item.delete)); // Remover los ítems eliminados
    };

    const handleDeleteClick = (index: number) => {
        const updatedData = editedData
            .filter(item => !item.delete)
            .map((item, i) => {
                if (i === index) {
                    return { ...item, delete: true }; // Marcar el ítem como eliminado
                }
                return item;
            });
        setEditedData(updatedData);
    };

    useEffect(() => {
        setEditedData(
            data.map(item => ({
                ...item,
                new: false,
                edited: false,
                delete: false
            }))
        );
    }, [data]);

    return (
        <div className='garbagecontrol__detail'>
            <div className='garbagecontrol__detail__title subtitle'>
                Definición costo gestión de basura
            </div>
            <div className='garbagecontrol__detail__subtitle subtitle'>
                Inmueble: {property}
            </div>
            <div className='garbagecontrol__detail__content subtitle'>
                <div className='garbagecontrol__detail__content__tableContainer'>
                    <table className='garbagecontrol__detail__content__tableContainer__table subtitle'>
                        <thead className='garbagecontrol__detail__content__tableContainer__table__head'>
                            <tr className='garbagecontrol__detail__content__tableContainer__table__head__row'>
                                <th className='garbagecontrol__detail__content__tableContainer__table__head__row__item number-column'></th>
                                <th className='garbagecontrol__detail__content__tableContainer__table__head__row__item detail-column'>
                                    Descripción
                                </th>
                                <th className='garbagecontrol__detail__content__tableContainer__table__head__row__item cost-column'>
                                    Costo total
                                </th>
                                <th className='garbagecontrol__detail__content__tableContainer__table__head__row__item description-column'>
                                    Detalle
                                </th>
                                <th className='garbagecontrol__detail__content__tableContainer__table__head__row__item icons-column'></th>
                            </tr>
                        </thead>
                        <tbody className='garbagecontrol__detail__content__tableContainer__table__body'>
                            {editedData
                                .filter(item => !item.delete)
                                .map((item, index) => (
                                    <tr
                                        className='garbagecontrol__detail__content__tableContainer__table__body__row detail-column'
                                        key={index}
                                    >
                                        <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item number-column'>
                                            {index + 1}
                                        </td>
                                        <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item description-column'>
                                            {editingIndex === index ? (
                                                <input
                                                    className='garbagecontrol__detail__content__tableContainer__table__body__row__item__input'
                                                    type='text'
                                                    value={tempData.description}
                                                    onChange={e =>
                                                        handleInputChange(
                                                            'description',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                item.description
                                            )}
                                        </td>
                                        <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item cost-column'>
                                            {editingIndex === index ? (
                                                <input
                                                    className='garbagecontrol__detail__content__tableContainer__table__body__row__item__input'
                                                    type='text'
                                                    value={parseInt(
                                                        tempData.cost
                                                    )}
                                                    onChange={e =>
                                                        handleInputChange(
                                                            'cost',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                `$${parseFloat(
                                                    item.cost
                                                ).toLocaleString('es-CL', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}`
                                            )}
                                        </td>
                                        <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item'>
                                            {editingIndex === index ? (
                                                <input
                                                    className='garbagecontrol__detail__content__tableContainer__table__body__row__item__input'
                                                    type='text'
                                                    value={tempData.detail}
                                                    onChange={e =>
                                                        handleInputChange(
                                                            'detail',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                item.detail
                                            )}
                                        </td>
                                        <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item'>
                                            {editingIndex === index ? (
                                                <SaveFilled
                                                    style={{ color: '#C4C3C5' }}
                                                    onClick={() =>
                                                        handleSaveClick(index)
                                                    }
                                                />
                                            ) : (
                                                <div className='garbagecontrol__detail__content__tableContainer__table__body__row__item__icons'>
                                                    <EditFilled
                                                        style={{
                                                            color: '#C4C3C5'
                                                        }}
                                                        onClick={() =>
                                                            handleEditClick(
                                                                index
                                                            )
                                                        }
                                                    />
                                                    <DeleteFilled
                                                        style={{
                                                            color: '#C4C3C5'
                                                        }}
                                                        onClick={() =>
                                                            handleDeleteClick(
                                                                index
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            <tr className='garbagecontrol__detail__content__tableContainer__table__body__row detail-column'>
                                <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item number-column'>
                                    {editedData.length + 1}
                                </td>
                                <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item description-column'>
                                    {newItem ? (
                                        <input
                                            className='garbagecontrol__detail__content__tableContainer__table__body__row__item__input'
                                            type='text'
                                            value={newItem.description || ''}
                                            onChange={e =>
                                                handleNewItemChange(
                                                    'description',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    ) : (
                                        ''
                                    )}
                                </td>
                                <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item cost-column'>
                                    {newItem ? (
                                        <input
                                            className='garbagecontrol__detail__content__tableContainer__table__body__row__item__input'
                                            type='text'
                                            value={newItem.cost || ''}
                                            onChange={e =>
                                                handleNewItemChange(
                                                    'cost',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    ) : (
                                        ''
                                    )}
                                </td>
                                <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item'>
                                    {newItem ? (
                                        <input
                                            className='garbagecontrol__detail__content__tableContainer__table__body__row__item__input'
                                            type='text'
                                            value={newItem.detail || ''}
                                            onChange={e =>
                                                handleNewItemChange(
                                                    'detail',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    ) : (
                                        ''
                                    )}
                                </td>
                                <td className='garbagecontrol__detail__content__tableContainer__table__body__row__item'>
                                    {newItem ? (
                                        <SaveFilled
                                            style={{ color: '#C4C3C5' }}
                                            onClick={handleNewItemSaveClick}
                                        />
                                    ) : (
                                        <PlusCircleFilled
                                            style={{ color: '#C4C3C5' }}
                                            onClick={() =>
                                                setNewItem({
                                                    description: '',
                                                    cost: '',
                                                    detail: ''
                                                })
                                            }
                                        />
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='garbagecontrol__detail__buttons subtitle'>
                <button
                    type='button'
                    className='button button-body--save-management-cost subtitle'
                    onClick={SaveAll}
                >
                    Guardar
                </button>
                <button
                    type='button'
                    className='button button-body--close-month subtitle'
                    onClick={handleCancelClick}
                >
                    Cancelar
                </button>
            </div>
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default GarbageCostsDetail;
