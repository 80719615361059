//  <--COMPONENTS--> //
import React, { useEffect, useState /* , useRef, useCallback */ } from 'react';
import { useNavigate } from 'react-router';
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import { ConfigProvider, Tooltip, Checkbox, Popover } from 'antd';
import {
    FileFilled,
    LeftOutlined,
    RightOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    MoreOutlined,
    FileExcelFilled,
    CheckCircleFilled,
    PictureFilled,
    FilterOutlined
} from '@ant-design/icons';
//  <--REDUX--> //
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { PropertyStoreInterface } from '../../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../../store/slices/property-unit/property-unit.interface';
import { MaterialStoreInterface } from '../../../../store/slices/material/material.interface';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { setMaterialData } from '../../../../store/slices/material';
/* import useWindowDimensions from '../../../../config/hooks/useWindowDimentions'; */
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import './registers.scss';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { lang } from '../../../langs';
import { NormalizeName } from '../../../../config/utils/Utilities';
import {
    ApiDataRegisters,
    ApiFileRelationRegisterId
} from '../../../../config/service';
import dayjs from 'dayjs';
import CloseIcon from '@material-ui/icons/Close';
import DownloadRequirementsFiles from '../../../../components/downloadRequirementFiles/download-requirements-files';

interface MaterialItem {
    name: string;
    selected: boolean;
    show: boolean;
}

const Registers = () => {
    const { userData, preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );

    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageDataCenter: {
                pageEvolution: { title },
                months
            }
        }
    } = lang;
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { propertyData, selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );
    const { materialData }: MaterialStoreInterface = useAppSelector(
        state => state.material
    );
    /* const { width } = useWindowDimensions(); */
    /* const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); */
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<number>(0);
    const [dateFilterRange, setDateFilterRange] = useState<any>({});
    const [monthData, setMonthData] = useState<any[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(true);
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [files, setFiles] = useState<any[]>([]);
    const [expandOpen, setExpandOpen] = useState<boolean>(false);
    const [weeks, setWeeks] = useState<any[]>([]);
    const [filterOptions, setFilterOptions] = useState({
        gestorName: [] as string[]
    });
    const [filters, setFilters] = useState({
        gestorName: [] as string[]
    });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    /* useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]); */

    const GetTableData = async data => {
        const response = await GetData(
            ApiDataRegisters,
            HTTP_METHODS.POST,
            data
        );
        setMonthData(response.data);
    };

    useEffect(() => {
        const uniqueGestors: string[] = Array.from(
            new Set(monthData.map(register => register.Gestor))
        );

        // replace null gestor with string
        const updatedArray = uniqueGestors.map(item =>
            item === null ? '(medición interna)' : item
        );

        setFilterOptions({
            gestorName: updatedArray
        });
        // gestor initial value is on, then you can deselect
        setFilters({
            gestorName: updatedArray
        });
    }, [monthData]);

    const handleCheckboxChange = (checked, value, dataField) => {
        // Actualizar el estado de los filtros seleccionados
        setFilters(prevFilters => {
            const updatedFilters = {
                ...prevFilters,
                [dataField]: checked
                    ? [...prevFilters[dataField], value] // Agrega el valor si está marcado
                    : prevFilters[dataField].filter(item => item !== value) // Elimina el valor si está desmarcado
            };
            return updatedFilters;
        });
    };

    const FormatTableData = (data: any) => {
        let filteredData = data;

        // Filtrado de datos según las selecciones
        if (selected.length > 0 && selectedUnits.length > 0) {
            filteredData = data.filter(
                item =>
                    selected.some(
                        selectedItem =>
                            item.property === selectedItem.id &&
                            item.propertyUnit === null
                    ) ||
                    selectedUnits.some(
                        selectedUnit => item.propertyUnit === selectedUnit.id
                    )
            );
        } else if (selected.length > 0) {
            filteredData = data.filter(item =>
                selected.some(
                    selectedItem =>
                        item.property === selectedItem.id &&
                        item.propertyUnit === null
                )
            );
        } else if (selectedUnits.length > 0) {
            filteredData = data.filter(item =>
                selectedUnits.some(
                    selectedUnit => item.propertyUnit === selectedUnit.id
                )
            );
        }

        // Filtrado por gestor
        filteredData = filteredData.filter(item =>
            filters.gestorName.includes(item.Gestor ?? '(medición interna)')
        );

        // Mapeo para reemplazar nulls y ordenar los datos
        const sortedData = filteredData
            .map(item => {
                const newItem = { ...item };
                Object.keys(newItem).forEach(key => {
                    if (newItem[key] === null) {
                        newItem[key] = '-';
                    }
                });
                return newItem;
            })
            .sort((a, b) => {
                const dateA = new Date(a.Fecha).getTime();
                const dateB = new Date(b.Fecha).getTime();
                return dateB - dateA;
            });
        setTableData(sortedData);
    };

    const unselectAll = () => {
        const updatedMaterials = materialData.map(material => {
            return { ...material, selected: !selectAll };
        });
        dispatch(setMaterialData(updatedMaterials));
        setSelectAll(!selectAll);
    };

    const GetDocuments = (registerId: string) => {
        FormatDocuments(registerId);
    };

    const FormatDocuments = async (registerId: string) => {
        const response = await GetData(
            ApiFileRelationRegisterId(registerId),
            HTTP_METHODS.GET
        );
        const filesArray = response.data
            .filter(item => item.file)
            .map(item => item.file);
        setFiles(filesArray);
        setOpenPopup(true);
    };

    const handleScroll = direction => {
        const container = document.querySelector(
            '.registers__container__filters__materials__filter__list'
        );

        if (container) {
            const scrollAmount = 200;

            if (direction === 'left') {
                container.scrollLeft -= scrollAmount;
            } else {
                container.scrollLeft += scrollAmount;
            }
        }
    };

    const changeMonth = (way: number) => {
        const currentIndex = months.indexOf(month);
        let newIndex = currentIndex + way;
        let newYear = year;

        if (newIndex < 0) {
            newIndex = months.length + newIndex;
            newYear -= 1;
        } else if (newIndex >= months.length) {
            newIndex = newIndex % months.length;
            newYear += 1;
        }

        setMonth(months[newIndex]);
        setYear(newYear);

        const startDate = dayjs(
            `${newYear}-${String(newIndex + 1).padStart(2, '0')}-01`
        );
        const endDate = startDate.endOf('month');
        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        setDateFilterRange({ ini: formattedStartDate, fin: formattedEndDate });

        // Generar las semanas del mes
        const weeks: any = [];
        let currentDay = startDate.startOf('week').add(1, 'day'); // Comenzar desde el primer lunes
        while (currentDay.isBefore(endDate)) {
            const endOfWeek = currentDay.endOf('week').format('YYYY-MM-DD'); // Obtener el final de la semana
            weeks.push(endOfWeek);
            currentDay = currentDay.add(1, 'week'); // Avanzar al siguiente lunes
        }
        setWeeks(weeks); // Actualizar el estado con las semanas
    };

    const ChangeMaterials = (material: MaterialItem) => {
        const updatedMaterials = materialData.map(materialItem =>
            materialItem.name === material.name
                ? { ...materialItem, selected: !materialItem.selected }
                : materialItem
        );
        dispatch(setMaterialData(updatedMaterials));
    };

    useEffect(() => {
        FormatTableData(monthData);
    }, [monthData, selected, selectedUnits, filters]);

    useEffect(() => {
        const ids = propertyData.map(item => item.id);
        const unitIds = propertyData.flatMap(property =>
            property.propertyUnits.map(unit => unit.id)
        );

        let gestor: string | null = null;
        for (const role of userData.roles) {
            if (role.gestorData) {
                gestor = role.gestorData.id;
                break;
            }
        }
        const data = {
            filters: {
                propertyIds: ids,
                units: unitIds,
                dateRange: dateFilterRange,
                materials: '',
                gestors: [gestor]
            }
        };
        if (dateFilterRange.ini && dateFilterRange.fin) {
            GetTableData(data);
        }
    }, [dateFilterRange]);

    useEffect(() => {
        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth(); // Mes actual (0-11)
        const currentYear = currentDate.getFullYear(); // Año actual
        const formattedCurrentMonth = String(currentMonthIndex + 1).padStart(
            2,
            '0'
        ); // Formatear mes a 2 dígitos (01-12)

        // Función para obtener el último día del mes
        const getLastDayOfMonth = (year, month: number) => {
            return new Date(year, month + 1, 0).getDate();
        };

        const lastDayCurrentMonth = getLastDayOfMonth(
            currentYear,
            currentMonthIndex
        ); // Último día del mes actual

        // Rango de fecha para el filtro (primer día y último día del mes)
        const iniDateFilter = `${currentYear}-${formattedCurrentMonth}-01`;
        const currentDateFilter = `${currentYear}-${formattedCurrentMonth}-${lastDayCurrentMonth}`;
        const dateFilterRangeObject = {
            ini: iniDateFilter,
            fin: currentDateFilter
        };
        setDateFilterRange(dateFilterRangeObject); // Actualizar rango de fechas

        setMonth(months[currentMonthIndex]); // Actualizar mes
        setYear(currentYear); // Actualizar año

        // Calcular las semanas del mes
        const startDate = dayjs(`${currentYear}-${formattedCurrentMonth}-01`); // Primer día del mes
        const endDate = startDate.endOf('month'); // Último día del mes
        const weeks: any[] = [];

        let currentDay = startDate.startOf('week').add(1, 'day'); // Comenzar desde el primer lunes
        while (currentDay.isBefore(endDate) || currentDay.isSame(endDate)) {
            // Asegurarse de incluir la última semana
            const endOfWeek = currentDay.endOf('week').format('YYYY-MM-DD'); // Obtener el final de la semana
            weeks.push(endOfWeek); // Añadir el final de la semana al array
            currentDay = currentDay.add(1, 'week'); // Avanzar al siguiente lunes
        }

        setWeeks(weeks); // Actualizar estado con las semanas calculadas
    }, [months]);

    useEffect(() => {
        const allSelected = materialData.every(material => material.selected);
        setSelectAll(allSelected);
    }, [materialData]);

    useEffect(() => {
        const updatedMaterials = materialData.map(material => {
            // Verificar si el material está presente en tableData
            const isInTableData = tableData.some(
                item => item.Material === material.name
            );

            // Actualizar la propiedad 'show' basándote en si está en tableData
            return {
                ...material,
                show: isInTableData
            };
        });

        // Despachar los materiales actualizados
        dispatch(setMaterialData(updatedMaterials));
    }, [tableData]);

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className={`${expandOpen ? 'open-expand' : 'registers'}`}>
                {!expandOpen && <Header title={{ name: title }} />}
                <div
                    className={`registers__container ${
                        expandOpen ? 'full-height' : ''
                    }`}
                >
                    <div className='registers__container__filters'>
                        {/*  <div className='registers__container__filters__tipology'>
                            <div className='registers__container__filters__tipology__title subtitle'>
                                Tipología
                            </div>
                            <div className='registers__container__filters__tipology__tipology-picker'></div>
                            
                        </div> */}
                        <div className='registers__container__filters__materials'>
                            <div className='registers__container__filters__materials__title subtitle'>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Checkbox: {
                                                colorPrimary: '#C4C3C5',
                                                colorBorder: '#C4C3C5',
                                                colorText: '#C4C3C5',
                                                colorPrimaryHover: '#C4C3C5'
                                            }
                                        }
                                    }}
                                >
                                    <Checkbox
                                        className='registers__container__filters__materials__title__checkbox'
                                        checked={selectAll}
                                        onChange={() => unselectAll()}
                                    />
                                    <label htmlFor='selectAll'>
                                        Seleccionar todos
                                    </label>
                                </ConfigProvider>
                            </div>
                            <div className='registers__container__filters__materials__filter'>
                                <button
                                    className='registers__container__filters__materials__filter__arrow-btn title'
                                    onClick={() => handleScroll('left')}
                                >
                                    <LeftOutlined style={{ height: '100%' }} />
                                </button>
                                <div className='registers__container__filters__materials__filter__list'>
                                    {materialData
                                        .filter(item => item.show)
                                        .map(material => (
                                            <Tooltip
                                                title={
                                                    <span
                                                        key={material.name}
                                                        className='main_menu__grid-container__tooltip'
                                                    >
                                                        {material.name}
                                                    </span>
                                                }
                                                color='#fff'
                                                key={material.name}
                                            >
                                                <button
                                                    key={material.name}
                                                    onClick={() =>
                                                        ChangeMaterials(
                                                            material
                                                        )
                                                    }
                                                >
                                                    <img
                                                        className={`registers__container__filters__materials__filter__list__logo${
                                                            !material.selected
                                                                ? '__unselected'
                                                                : ''
                                                        }`}
                                                        src={`/svg-icons/material-icons/${NormalizeName(
                                                            material.name
                                                        )}.svg`}
                                                        alt={material.name}
                                                        key={material.name}
                                                        onError={e => {
                                                            const target =
                                                                e.target as HTMLImageElement;
                                                            target.src =
                                                                '/svg-icons/material-icons/general.svg';
                                                        }}
                                                    ></img>
                                                </button>
                                            </Tooltip>
                                        ))}
                                </div>
                                <button
                                    className='registers__container__filters__materials__filter__arrow-btn title'
                                    onClick={() => handleScroll('right')}
                                >
                                    <RightOutlined />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='registers__container__data'>
                        <div
                            className={`${'registers__container__data__header'}`}
                        >
                            <div className='registers__container__data__header__menu title'>
                                <Popover
                                    className='registers__popover'
                                    placement='bottom'
                                    trigger='click'
                                    content={
                                        <div className='registers__popover__container'>
                                            <DownloadRequirementsFiles
                                                registers={tableData
                                                    .filter(
                                                        item =>
                                                            materialData
                                                                .filter(
                                                                    material =>
                                                                        material.selected
                                                                ) // Filtra materiales seleccionados
                                                                .some(
                                                                    material =>
                                                                        material.name ===
                                                                        item.Material
                                                                ) // Compara con el Material del item
                                                    )
                                                    .map(item => item.id)}
                                                style={'text'}
                                            />
                                        </div>
                                    }
                                >
                                    <MoreOutlined
                                        style={{ color: '#1f1f1f' }}
                                    />
                                </Popover>
                            </div>
                            <div className='registers__container__data__header__months'>
                                <button
                                    className='registers__container__data__header__months__btn subtitle'
                                    onClick={() => changeMonth(-1)}
                                >
                                    <DoubleLeftOutlined
                                        style={{ color: '#C4C3C5' }}
                                    />
                                    Mes anterior
                                </button>
                                <div className='registers__container__data__header__months__title'>
                                    {month} {year}
                                </div>
                                <button
                                    className='registers__container__data__header__months__btn subtitle'
                                    onClick={() => changeMonth(1)}
                                >
                                    Mes siguiente
                                    <DoubleRightOutlined
                                        style={{ color: '#C4C3C5' }}
                                    />
                                </button>
                            </div>
                            <button
                                onClick={() => setExpandOpen(!expandOpen)}
                                className='registers__container__data__header__expandIcon'
                            >
                                <img
                                    className='registers__container__data__header__expandIcon__img'
                                    src={`/svg-icons/${
                                        expandOpen
                                            ? 'ContractIcon.svg'
                                            : 'ExpandIcon.svg'
                                    }`}
                                />
                            </button>
                        </div>
                        <div className='registers__container__data__tableContainer subtitle'>
                            <div className='registers__container__data__tableContainer__div'>
                                <table className='registers__container__data__tableContainer__div__table subtitle'>
                                    <thead className='registers__container__data__tableContainer__div__table__head'>
                                        <tr className='registers__container__data__tableContainer__div__table__head__row subtitle'>
                                            <th className='registers__container__data__tableContainer__div__table__head__row__item'>
                                                Fecha
                                            </th>
                                            <th className='registers__container__data__tableContainer__div__table__head__row__item'>
                                                Material
                                            </th>
                                            <th className='registers__container__data__tableContainer__div__table__head__row__item'>
                                                Cantidad (g)
                                            </th>
                                            <th className='registers__container__data__tableContainer__div__table__head__row__item'>
                                                Inmueble
                                            </th>
                                            <th className='registers__container__data__tableContainer__div__table__head__row__item'>
                                                Unidad
                                            </th>
                                            <th className='registers__container__data__tableContainer__div__table__head__row__item'>
                                                Gestor
                                                <Popover
                                                    placement='bottom'
                                                    trigger='click'
                                                    content={
                                                        <div
                                                            className='table__editable__thead__tr__th__text__popover subtitle'
                                                            style={{
                                                                height: '190px',
                                                                overflowX:
                                                                    'scroll'
                                                            }}
                                                        >
                                                            {filterOptions.gestorName?.map(
                                                                (
                                                                    value,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        className='table__editable__thead__tr__th__text__popover__item'
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <ConfigProvider
                                                                            theme={{
                                                                                components:
                                                                                    {
                                                                                        Checkbox:
                                                                                            {
                                                                                                colorPrimary:
                                                                                                    '#a2a2a3',
                                                                                                colorBorder:
                                                                                                    '#a2a2a3',
                                                                                                colorPrimaryHover:
                                                                                                    '#a2a2a3'
                                                                                            }
                                                                                    }
                                                                            }}
                                                                        >
                                                                            <Checkbox
                                                                                className={`table__editable__thead__tr__th__text__popover__item__checkbox`}
                                                                                onChange={e =>
                                                                                    handleCheckboxChange(
                                                                                        e
                                                                                            .target
                                                                                            .checked,
                                                                                        value,
                                                                                        'gestorName'
                                                                                    )
                                                                                }
                                                                                checked={filters.gestorName.includes(
                                                                                    value
                                                                                )}
                                                                            />
                                                                        </ConfigProvider>
                                                                        <div
                                                                            className={`table__editable__thead__tr__th__text__popover__item__label`}
                                                                        >
                                                                            {
                                                                                value
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    }
                                                >
                                                    <FilterOutlined
                                                        style={{
                                                            color: '#fff'
                                                        }}
                                                    />
                                                </Popover>
                                            </th>
                                            <th className='registers__container__data__tableContainer__div__table__head__row__item'>
                                                Disposición final
                                            </th>
                                            <th className='registers__container__data__tableContainer__div__table__head__row__item doc'>
                                                Doc
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='registers__container__data__tableContainer__div__table__body'>
                                        {tableData
                                            .filter(
                                                item =>
                                                    materialData
                                                        .filter(
                                                            material =>
                                                                material.selected
                                                        ) // Filtra materiales seleccionados
                                                        .some(
                                                            material =>
                                                                material.name ===
                                                                item.Material
                                                        ) // Compara con el Material del item
                                            )
                                            .map((item, index, arr) => {
                                                const currentDate = new Date(
                                                    item.Fecha
                                                )
                                                    .toISOString()
                                                    .split('T')[0]; // Fecha del item actual en formato YYYY-MM-DD
                                                const currentWeek = weeks.find(
                                                    weekEnd =>
                                                        currentDate <= weekEnd
                                                ); // Encuentra la semana actual

                                                // Verifica si es el primer elemento de una nueva semana
                                                const prevItem = arr[index - 1];
                                                const prevDate = prevItem
                                                    ? new Date(prevItem.Fecha)
                                                          .toISOString()
                                                          .split('T')[0]
                                                    : null;
                                                const prevWeek = prevDate
                                                    ? weeks.find(
                                                          weekEnd =>
                                                              prevDate <=
                                                              weekEnd
                                                      )
                                                    : null;
                                                const isNewWeek =
                                                    currentWeek !== prevWeek; // Si la semana ha cambiado

                                                return (
                                                    <React.Fragment
                                                        key={`fragment-${index}`}
                                                    >
                                                        {index !== 0 &&
                                                            isNewWeek && ( // Solo agrega la línea si no es el primer elemento
                                                                <tr>
                                                                    <td
                                                                        colSpan={
                                                                            8
                                                                        }
                                                                        className='last-of-week'
                                                                    >
                                                                        {/* Línea divisoria entre semanas */}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        <tr
                                                            className={`registers__container__data__tableContainer__div__table__body__row`}
                                                            key={index}
                                                        >
                                                            <td className='registers__container__data__tableContainer__div__table__body__row__item'>
                                                                <Tooltip
                                                                    title={
                                                                        <span className='main_menu__grid-container__tooltip'>
                                                                            {new Date(
                                                                                item.Fecha
                                                                            )
                                                                                .toISOString()
                                                                                .replace(
                                                                                    'T',
                                                                                    ' '
                                                                                )
                                                                                .slice(
                                                                                    0,
                                                                                    16
                                                                                )}
                                                                        </span>
                                                                    }
                                                                    color='#fff'
                                                                >
                                                                    {
                                                                        new Date(
                                                                            item.Fecha
                                                                        )
                                                                            .toISOString()
                                                                            .split(
                                                                                'T'
                                                                            )[0]
                                                                    }
                                                                </Tooltip>
                                                            </td>
                                                            <td className='registers__container__data__tableContainer__div__table__body__row__item'>
                                                                <div className='registers__container__data__tableContainer__div__table__body__row__item__container'>
                                                                    <img
                                                                        className='registers__container__data__tableContainer__div__table__body__row__item__container__img'
                                                                        src={`/svg-icons/material-icons/${NormalizeName(
                                                                            item.Material
                                                                        )}.svg`}
                                                                    />
                                                                    {
                                                                        item.Material
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className='registers__container__data__tableContainer__div__table__body__row__item'>
                                                                {parseFloat(
                                                                    item.Cantidad
                                                                ).toLocaleString(
                                                                    'es-CL',
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 1
                                                                    }
                                                                )}
                                                            </td>
                                                            <td className='registers__container__data__tableContainer__div__table__body__row__item'>
                                                                {item.Inmueble}
                                                            </td>
                                                            <td className='registers__container__data__tableContainer__div__table__body__row__item'>
                                                                {item.Unidad ??
                                                                    'N/A'}
                                                            </td>
                                                            <td className='registers__container__data__tableContainer__div__table__body__row__item'>
                                                                {item.Gestor ??
                                                                    'N/A'}
                                                            </td>
                                                            <td className='registers__container__data__tableContainer__div__table__body__row__item'>
                                                                {item.DisposicionFinal ??
                                                                    'N/A'}
                                                            </td>
                                                            <td className='registers__container__data__tableContainer__div__table__body__row__item'>
                                                                <FileFilled
                                                                    style={{
                                                                        color: '#a2a2a3',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() =>
                                                                        GetDocuments(
                                                                            item.id
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <button
                        className='registers__container__btn'
                        onClick={() => navigate('/panel/evolution')}
                    >
                        Volver al gráfico de este reporte
                    </button>
                </div>
            </div>
            {openPopup && (
                <div className='files-popup'>
                    <div className='files-popup__main'>
                        <div className='files-popup__main__title subtitle'>
                            <span className='files-popup__main__title__text'>
                                Documentos de respaldo
                            </span>
                            <CloseIcon
                                className='files-popup__main__title__close'
                                onClick={() => setOpenPopup(!openPopup)}
                            />
                        </div>
                        {files.length > 0 ? (
                            files.map((item, index) => (
                                <div
                                    className='files-popup__main__text subtitle'
                                    key={index}
                                >
                                    {item.category === 'massive-upload' ? (
                                        <FileExcelFilled
                                            className='files-popup__main__text__icon title'
                                            style={{ color: '#a2a2a3' }}
                                        />
                                    ) : item.category === 'photos' ? (
                                        <PictureFilled
                                            className='files-popup__main__text__icon title'
                                            style={{ color: '#a2a2a3' }}
                                        />
                                    ) : (
                                        <CheckCircleFilled
                                            className='files-popup__main__text__icon title'
                                            style={{ color: '#a2a2a3' }}
                                        />
                                    )}
                                    <button
                                        className='files-popup__main__text__label subtitle'
                                        onClick={() =>
                                            window.open(item.path, '_blank')
                                        }
                                    >
                                        {item.path
                                            ? item.path.split('/').pop()
                                            : 'Sin archivo'}
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className='files-popup__main__text subtitle'>
                                Este registro no tiene documentos de respaldo
                            </div>
                        )}
                        {/* {files.length > 0 && (
                            <button className='button button-body--save-configReport'>
                                Descargar todos
                            </button>
                        )} */}
                    </div>
                </div>
            )}
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default Registers;
