import { FormBuilderFields } from '../../../../../components/formBuilder/form-builder.interface';

export const FormCreatePropertyUnit: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Piso',
        fieldNameId: 'floor',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el piso',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 1,
                state: true,
                message: 'Mínimo 1 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Superficie',
        fieldNameId: 'surface',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la superficie',
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Categoría (restaurante, oficina, etc.)',
        fieldNameId: 'category',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione la categoría',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Inmueble',
        fieldNameId: 'property',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un inmueble',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
];
