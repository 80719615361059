import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import './sinader.scss';
// <--COMPONENTS--> //
/* import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd'; */
/* import type { RadioChangeEvent, UploadProps } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface'; */
import CloseIcon from '@material-ui/icons/Close';
import Table from '../../components/sinader-table/table';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
import Header from '../../components/header/header';
import Legend from './components/legend';
import { UploadOutlined } from '@ant-design/icons';
// <--REDUX--> //
import { useAppSelector } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
//  <--HOOKS--> //
import { useToast } from '../../config/hooks/useToast';
import { useAlert } from '../../config/hooks/useAlert';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
// <--INTERFACE--> //
import { lang } from '../langs';
import {
    ArrayTbl,
    tableData
} from '../../components/sinader-table/data/columns_table';
import { TableEditableTypesValues } from '../../components/sinader-table/interface/table.interface';
import {
    ApiFinalDisposalUnit,
    ApiManagement,
    ApiSinader,
    ApiSinaderMonthly,
    ApiSinaderMasive,
    ApiSinaderData,
    ApiTruckByGestorList,
    ApiFilesMasiveUpload,
    ApiFilesTicketBackup,
    ApiFileRelations
} from '../../config/service';
import {
    MonthlyClosureInterface,
    MonthlyClosureSaveInterface,
    SinaderExcelImportInterface,
    TruckInterfaceClear
} from '../../interface';
import dayjs from 'dayjs';

interface ResultInterface {
    col: number;
    element: string;
    message: string;
    row: number;
    type: string;
}

const AutocompleteArray = () => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { userData, preferences, defaultProfile }: UserStoreInterface =
        useAppSelector(state => state.users);
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            sinader: {
                autocompleteArray: {
                    title,
                    autocompleteButton,
                    /* closeMonthButton,
                    closeMessage,
                    closeWarning, */
                    saveButton,
                    downloadButton,
                    uploadButton,
                    confirmTitle,
                    confirmMessage,
                    masiveTitle,
                    loadTitle,
                    errorTitle,
                    errorMessage,
                    downloadTemplate,
                    selectBtn,
                    fileSelectedTitle,
                    months
                }
            }
        }
    } = lang;
    const [tblReady, setTblReady] = useState<boolean>(false);
    const [dataTable, setDataTable] = useState<MonthlyClosureInterface[]>([]);
    const [autocomplete, setAutocomplete] = useState<boolean>(false);
    const [trucksList, setTrucksList] = useState<TruckInterfaceClear[]>([]);
    const [establishmentList, setEstablishmentList] = useState<Object[]>([]);
    const [managementList, setManagementList] = useState<Object[]>([]);
    const [refreshTable, setRefreshTable] = useState<number>(0);
    const [popUpOpen, setPopUpOpen] = useState<boolean>(false);
    const [evidencePopUpOpen, setEvidencePopUpOpen] = useState<boolean>(false);
    const [expandOpen, setExpandOpen] = useState<boolean>(false);
    const [onSaveChange, setOnSaveChange] = useState<boolean>(false);
    const [uploadData, setuploadData] = useState<SinaderExcelImportInterface[]>(
        []
    );
    const [dateIni, setDateIni] = useState<string>('');
    const [dateFin, setDateFin] = useState<string>('');
    const [visibleErrors, setVisibleErrors] = useState<ResultInterface[]>([]);
    const [gestorName, setGestorName] = useState<string | null>();
    const [period, setPeriod] = useState<string>('');
    const [selectedFileName, setSelectedFileName] = useState<string>('');
    /* const [selectRegisters, setSelectRegisters] = useState<boolean>(false); */
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [excelFile, setExcelFile] = useState<any>([]);

    useEffect(() => {
        ArrayTbl[3].editableListData = trucksList;
    }, [trucksList]);

    useEffect(() => {
        ArrayTbl[5].editableListData = establishmentList;
    }, [establishmentList]);

    useEffect(() => {
        ArrayTbl[6].editableListData = managementList;
    }, [managementList]);

    useEffect(() => {
        if (dateIni !== '') {
            CallMainData(1, tableData.regXpage, true);
        }
        const date = dayjs(dateIni);
        const year = date.year();
        const monthIndex = date.month();
        const monthName = months[monthIndex];
        const newPeriod = `${monthName} ${year}`;
        setPeriod(newPeriod);
    }, [dateIni]);

    useEffect(() => {
        getDates();
    }, []);

    const getDates = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const today = `${year}-${month}-${day}`;

        const isFirstHalfOfMonth = date.getDate() < 15;

        if (isFirstHalfOfMonth) {
            date.setMonth(date.getMonth() - 1);
        }

        date.setDate(1);
        const iniYear = date.getFullYear();
        const iniMonth = String(date.getMonth() + 1).padStart(2, '0');
        const iniDay = String(date.getDate()).padStart(2, '0');
        const ini = `${iniYear}-${iniMonth}-${iniDay}`;
        setDateIni(ini);
        const fin = moment(today)
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD');
        setDateFin(fin);
    };

    /**
     * @description function that is executed when the save button is pressed
     * @function Save is a function that launches an alert to confirm the save of a item
     * @param id is a string that contains the id of the item to be saved
     */
    const OnSave = async dataSinader => {
        const dataFiltered: MonthlyClosureSaveInterface[] = dataSinader.map(
            (item: MonthlyClosureSaveInterface) => {
                return {
                    rowEdited: item.rowEdited,
                    truckId: item.truckId,
                    finalDUId: item.finalDUId,
                    treatmentId: item.treatmentId,
                    id: item.id,
                    mcid: item.mcid,
                    truck_autofill: item.truck_autofill,
                    fdu_autofill: item.fdu_autofill,
                    management_autofill: item.management_autofill,
                    treatment_autofill: item.treatment_autofill,
                    weight: item.weight
                };
            }
        );
        const response = await GetData(
            ApiSinader,
            HTTP_METHODS.POST,
            dataFiltered
        );
        const { status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setTimeout(() => {
                CallMainData(1, tableData.regXpage, false, true);
            }, 1000);
            setOnSaveChange(false);
        }
    };

    /**
     * @description
     * @function CallMainData is a function that returns a promise with the autocomplete-array list
     */
    const CallMainData = async (
        page,
        size,
        callInitialData = false,
        refresh = false
    ) => {
        const gestor = userData.roles.find(role => role.gestorData);
        const name = gestor ? gestor.gestorData.name : null;
        setGestorName(name);
        const response = await GetData(ApiSinaderMonthly, HTTP_METHODS.POST, {
            from: page,
            registers: size,
            role: userData.roles[defaultProfile].id,
            dateini: dateIni,
            datefin: dateFin,
            gestorname: name,
            propertyId:
                userData.roles[defaultProfile].name === 'Waste Admin'
                    ? 'true'
                    : null
        });
        const { data, status } = response;
        setDataTable(data.results);
        // setTotalRegisters(data.total);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
        const listUniquegestors = data.results
            ? data.results
                  .map(item => item.gestorId)
                  .filter((value, index, self) => self.indexOf(value) === index)
            : [];
        if (listUniquegestors.length > 0) {
            CallApiTruckByGestors(listUniquegestors);
        }
        setTblReady(true);
        if (callInitialData) {
            CallOthersServices();
        }
        if (refresh) {
            Refresh();
        }
    };

    const CallApiTruckByGestors = async (list: string[]) => {
        const response = await GetData(
            ApiTruckByGestorList,
            HTTP_METHODS.POST,
            { list }
        );
        const { data, status } = response;
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
        const finalTruckList = data.map(item => {
            return {
                gestor: item.gestor.id,
                truck: item.id,
                patent: item.patent,
                id: item.id
            };
        });
        setTrucksList(finalTruckList);
    };

    const Refresh = () => {
        setRefreshTable(refreshTable + 1);
    };

    const CallOthersServices = () => {
        // CallAddsData(ApiTruckByGestor, setTrucksList);
        CallAddsData(ApiFinalDisposalUnit, setEstablishmentList);
        CallAddsData(ApiManagement, setManagementList);
        // CallAddsData(ApiTreatment, setTreatmentList);
    };

    /**
     * @description
     * @function CallTruckData is a function that returns a promise with the truck list
     */
    const CallAddsData = async (url, controller) => {
        const response = await GetData(url, HTTP_METHODS.GET);
        const { data, status } = response;
        controller(data);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    const ChangeDate = (way: number) => {
        const [month, year] = period.split(' ');
        const currentIndex = months.indexOf(month);
        let newIndex = currentIndex + way;
        let newYear = parseInt(year);

        if (newIndex < 0) {
            newIndex = months.length + newIndex;
            newYear -= 1;
        } else if (newIndex >= months.length) {
            newIndex = newIndex % months.length;
            newYear += 1;
        }

        const newMonthStr = String(newIndex + 1).padStart(2, '0');
        const newYearStr = String(newYear);
        const startDate = dayjs(`${newYearStr}-${newMonthStr}-01`)
            .startOf('month')
            .format('YYYY-MM-DD');
        const endDate = dayjs(`${newYearStr}-${newMonthStr}-01`)
            .endOf('month')
            .format('YYYY-MM-DD');

        setDateIni(startDate);
        setDateFin(endDate);
    };

    const OnAutocomplete = () => {
        ConfigAlert.title = confirmTitle;
        ConfigAlert.message = confirmMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: AutocompleteData };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    const AutocompleteData = () => {
        setAutocomplete(true);
    };

    const HandleFileUpload = e => {
        setExcelFile([...e.target.files]);
        const file = e.target.files[0];
        const reader = new FileReader();
        setSelectedFileName(file ? file.name : '');
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = e => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData: SinaderExcelImportInterface[] =
                XLSX.utils.sheet_to_json(sheet, {
                    /* raw: false, // Evalúa las fórmulas */
                    defval: null // Usar `null` en lugar de vacío para celdas sin valor
                });
            const filteredData = parsedData.filter(row =>
                Object.values(row).some(value => value !== null && value !== '')
            );
            setuploadData(filteredData);
        };
    };

    const UploadDataToServer = async () => {
        const response = await GetData(
            ApiSinaderMasive(1),
            HTTP_METHODS.POST,
            uploadData
        );
        const { data, status, message } = response;
        if (!status) {
            setVisibleErrors(data?.results);
        } else {
            const successId = data.results.saved.map(item => item.id);
            // console.log(suceesses)
            SaveFile(successId);
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setPopUpOpen(false);
        }
    };

    /* const closeError = index => {
        const updatedErrors = visibleErrors.filter((_, i) => i !== index);
        setVisibleErrors(updatedErrors);
    }; */

    const DownloadData = async () => {
        const data = {
            /* dateini: '2023-01-01',
            datefin: '2023-10-31', */
            dateini: dateIni,
            datefin: dateFin,
            gestorname: gestorName,
            propertyId: null,
            unitId: null,
            /* gestorname: 'Recupac', */
            isForm: false
        };

        const response = await GetData(ApiSinaderData, HTTP_METHODS.POST, data);
        const {
            data: { filepathdownload }
        } = response;
        window.open(filepathdownload, '_blank');
    };

    const SaveChanges = () => {
        setOnSaveChange(true);
    };

    const SaveFile = async (list: string[]) => {
        const formData = new FormData();
        if (excelFile.length > 0) {
            excelFile.forEach((file: any) => {
                formData.append('files', file, file.name);
            });
        }
        const { data } = await GetData(
            ApiFilesMasiveUpload,
            HTTP_METHODS.POST,
            formData,
            {},
            true
        );

        const obj = {
            files: data.map(item => item.response.id),
            origin: 'data-registry',
            relatedIds: list
        };
        await GetData(ApiFileRelations, HTTP_METHODS.POST, obj, {}, false);
    };

    /** UPLOAD MULTIPLE FILES **/
    const [files, setFiles] = useState<any>([]);
    const SubmitImages = async (event: any) => {
        event.preventDefault();
        const formData = new FormData();
        const imagefile: any = document.querySelector('#productFile');
        if (imagefile) {
            files.forEach(file => {
                formData.append('files', file, file.name);
            });
        }
        try {
            const { data, status, message } = await GetData(
                ApiFilesTicketBackup,
                HTTP_METHODS.POST,
                formData,
                {},
                true
            );
            if (status && data) {
                const obj = {
                    files: data.map(item => item.response.id),
                    origin: 'data-registry',
                    relatedIds: selectedIds
                };
                const response = await GetData(
                    ApiFileRelations,
                    HTTP_METHODS.POST,
                    obj,
                    {},
                    false
                );
                if (response.status) {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Success;
                    setConfigToast(ConfigToast);
                }
            } else {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleMultipleChange = (event: any) => {
        setFiles([...event.target.files]);
    };

    const handleSelectedIds = (ids: string[]) => {
        if (JSON.stringify(ids) !== JSON.stringify(selectedIds)) {
            setSelectedIds(ids);
        }
    };

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar />
            <div className={`${expandOpen ? 'open-expand' : 'sinader'}`}>
                {!expandOpen && <Header title={{ name: title }} />}
                <div
                    className={`sinader__table ${
                        expandOpen ? 'full-height' : ''
                    }`}
                >
                    {/* <p>
                        {JSON.stringify(tblReady)}
                        {JSON.stringify(data)}
                    </p> */}
                    {tblReady && (
                        <Table
                            data={dataTable}
                            headers={ArrayTbl}
                            saveData={OnSave}
                            type={TableEditableTypesValues.inRow}
                            highlight={{
                                field: 'autofilled',
                                addClass: 'filled',
                                show: autocomplete
                            }}
                            onRefresh={refreshTable}
                            onSaveChange={onSaveChange}
                            changeDate={ChangeDate}
                            period={period}
                            /* selectRegisters={selectRegisters} */
                            onChangeSelected={handleSelectedIds}
                        />
                    )}
                    <div className='sinader__table__btns'>
                        <button
                            className='sinader__table__btns__btn'
                            onClick={() => setExpandOpen(!expandOpen)}
                        >
                            <img
                                className='sinader__table__btns__btn__logo'
                                src={`/svg-icons/${
                                    expandOpen
                                        ? 'ContractIcon.svg'
                                        : 'ExpandIcon.svg'
                                }`}
                            />
                        </button>
                        <div className='sinader__table__btns__all'>
                            {/* <button
                                type='button'
                                className='button button-body--close-month subtitle'
                                onClick={() =>
                                    setSelectRegisters(!selectRegisters)
                                }
                            >
                                {!selectRegisters
                                    ? 'Seleccionar registros'
                                    : 'Editar registros'}
                            </button> */}
                            <button
                                type='button'
                                className='button button-body--close-month subtitle'
                                onClick={() =>
                                    setEvidencePopUpOpen(!evidencePopUpOpen)
                                }
                            >
                                Subir documentos
                            </button>
                            <button
                                type='button'
                                className='button button-body--close-month subtitle'
                                onClick={() => setPopUpOpen(!popUpOpen)}
                            >
                                {uploadButton}
                            </button>
                            <button
                                type='button'
                                className='button button-body--close-month subtitle'
                                onClick={DownloadData}
                            >
                                {downloadButton}
                            </button>
                            {gestorName && (
                                <button
                                    type='button'
                                    className='button button-body--autocomplete subtitle'
                                    onClick={OnAutocomplete}
                                >
                                    {autocompleteButton}
                                </button>
                            )}
                            <button
                                type='button'
                                className='button button-body--save-sinader subtitle'
                                onClick={SaveChanges}
                            >
                                {saveButton}
                            </button>
                        </div>
                    </div>
                </div>
                <Legend />
            </div>
            {LoadingData && <LoaderElement />}
            {evidencePopUpOpen && (
                <div className='popUp'>
                    <div className='popUp__main'>
                        <div className='popUp__main__container'>
                            <div className='popUp__main__title title'>
                                <span> Carga de documentos </span>
                                <CloseIcon
                                    className='popUp__main__title__close'
                                    onClick={() => setEvidencePopUpOpen(false)}
                                />
                                <hr />
                            </div>

                            <div className='popUp__main__text subtitle'>
                                <input
                                    type='file'
                                    id='productFile'
                                    name='productFile'
                                    accept='image/*, .pdf, .txt, .pptx'
                                    capture='user'
                                    multiple={true}
                                    onChange={handleMultipleChange}
                                />
                            </div>
                            <div className='popUp__main__buttons app-d-flex__center childs_uniform'>
                                <button
                                    className='button button-body--sinader subtitle'
                                    onClick={SubmitImages}
                                >
                                    {loadTitle}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {popUpOpen && (
                <div className='popUp'>
                    <div className='popUp__main'>
                        <div className='popUp__main__container'>
                            <div className='popUp__main__title title'>
                                <span> {masiveTitle} </span>
                                <CloseIcon
                                    className='popUp__main__title__close'
                                    onClick={() => setPopUpOpen(false)}
                                />
                                <hr />
                            </div>

                            <div className='popUp__main__text subtitle'>
                                <label className='popUp__main__text__upload subtitle'>
                                    <input
                                        type='file'
                                        accept='.xlsx'
                                        onChange={HandleFileUpload}
                                        style={{ display: 'none' }}
                                    />
                                    <UploadOutlined />
                                    <div className='popUp__main__text__upload__label'>
                                        {selectBtn}
                                    </div>
                                </label>
                                <div className='popUp__main__text__fileName subtitle'>
                                    {fileSelectedTitle}
                                    {selectedFileName && (
                                        <span className='popUp__main__text__fileName__name'>
                                            {selectedFileName}
                                        </span>
                                    )}
                                </div>
                                <button
                                    className='popUp__main__text__template subtitle'
                                    onClick={() =>
                                        window.open('/template.xlsx', '_blank')
                                    }
                                >
                                    {downloadTemplate}
                                </button>
                            </div>
                            <div className='popUp__main__buttons app-d-flex__center childs_uniform'>
                                <button
                                    className='button button-body--sinader subtitle'
                                    onClick={UploadDataToServer}
                                >
                                    {loadTitle}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            ;
            {visibleErrors && visibleErrors.length > 0 && (
                <div className='error'>
                    <div className='error__main'>
                        <div className='error__main__title subtitle'>
                            <span className='error__main__title__text'>
                                {errorTitle} / {errorMessage}
                            </span>
                            <CloseIcon
                                className='error__main__title__close'
                                onClick={() => setVisibleErrors([])}
                            />
                        </div>
                        {visibleErrors.map((item, index) => (
                            <div
                                className='error__main__text subtitle'
                                key={index}
                            >
                                {item.message}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default AutocompleteArray;
