import { StackedBarChart } from '../../../charts';
import { useEffect } from 'react';

const StackedDC = StackedBarChart;
StackedDC.ConfigGC.plugins.legend.display = false;
StackedDC.ConfigGC.plugins.title.text = '';

interface Props {
    data: any;
    formattedLabels: any;
}

const ChartFormated = ({ data, formattedLabels }: Props) => {
    useEffect(() => {
        const totals: Record<number, number> = {};

        // Calcular los totales por índice
        data?.datasets?.forEach(item => {
            if (item.label !== 'Costo') {
                item.data.forEach((value, index) => {
                    totals[index] = (totals[index] || 0) + parseFloat(value);
                });
            }
        });

        // Detectar el máximo de los totales
        const maxTotal = Math.max(...Object.values(totals));

        // Configurar el eje Y dependiendo del valor máximo
        if (maxTotal >= 1_000_000) {
            StackedDC.AddTicksGC.title.text = 'Mil [M$]'; // Millones
        } else if (maxTotal >= 1_000) {
            StackedDC.AddTicksGC.title.text = 'Mil [k$]'; // Miles
        } else {
            StackedDC.AddTicksGC.title.text = '[l]'; // Unidades
        }

        // Configuración del gráfico
        StackedDC.ConfigGC.plugins.legend.display = false;
        StackedDC.ConfigGC.plugins.title.text = '';
        StackedDC.ConfigGC.scales.x.display = true;
        StackedDC.ConfigGC.scales.y1.title.text = 'Costo gestión [$]';
        StackedDC.ConfigGC.scales.y1.border.color = 'rgb(162 162 163)';
        StackedDC.ConfigGC.scales.y1.min = 0;

        // Obtener el valor máximo de costos
        const rawMaxCost = Math.max(
            ...data?.datasets
                ?.filter(item => item.label === 'Costo')
                .flatMap(item => item.data || [])
        );

        // Redondear el máximo a un número "redondo"
        const maxCost = Math.ceil(rawMaxCost / 10_000) * 10_000; // Redondear al siguiente múltiplo de 10,000

        StackedDC.ConfigGC.scales.y1.max = maxCost;

        // Calcular el paso entre etiquetas
        const stepSize = maxCost / 5;

        // Ajustar las etiquetas del eje Y1
        StackedDC.ConfigGC.scales.y1.ticks = {
            stepSize,
            font: { size: 10 },
            callback: function (value: number | string) {
                // Ajustar formato dependiendo del valor máximo
                if (maxCost >= 1_000_000) {
                    return `${(Number(value) / 1_000_000).toFixed(1)}M`;
                } else if (maxCost >= 1_000) {
                    return `${(Number(value) / 1_000).toFixed(1)}k`;
                }
                return `$${Number(value).toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                })}`;
            }
        } as any;

        // Configurar el eje Y principal
        StackedDC.ConfigGC.scales.y = {
            ...StackedDC.ConfigGC.scales.y,
            ...StackedDC.AddTicksGC
        };
    }, [data]);

    return (
        <>
            {data && (
                <StackedDC.StackedBarChartComponent
                    chartData={data}
                    options={{
                        ...StackedDC.ConfigGC,
                        scales: {
                            ...StackedDC.ConfigGC.scales,
                            x: {
                                ...StackedDC.ConfigGC.scales.x,
                                ticks: {
                                    ...StackedDC.ConfigGC.scales.x.ticks,
                                    callback: (value, index, values) => {
                                        return formattedLabels[index];
                                    }
                                }
                            }
                        }
                    }}
                    dateChangeManager={() => {}}
                />
            )}
        </>
    );
};

export default ChartFormated;
